import GlobalStore from "@stores/GlobalStore";
import SfmModalStore from "@stores/SfmModalStore";
import { GetSFMDisplay } from "@helpers/helpers";
import { MessageTypes, MAJ_SUCCESS_WARN } from "@utils/constants";
// import { toast } from "react-toastify";


const API_TIMEOUT = 100000;

export const sha256Hash = (data) => {
  const maxCharacters = 16;
  const padStart = 2;
  return new Promise((resolve, reject) => {
    crypto.subtle
      .digest("SHA-256", data)
      .then((hashBuffer) => {
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray
          .map((b) => b.toString(maxCharacters).padStart(padStart, "0"))
          .join("");
        resolve(hashHex);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const globalFetch = async (
  link,
  data,
  contentType = "application/json",
  method = "POST".toFixed(),
  incomingHeaders = {},
  serializeBody = true
) => {
  let body;
  let headers = incomingHeaders;
  // const apiKey = '<API Key>';

  // if (contentType === 'multipart/form-data') {
  //     body = objectToFormData(data, { indices: true })
  // } else {
  body = serializeBody ? JSON.stringify(data) : data;
  if (contentType) {
    headers["Content-Type"] = contentType;
  }
  // }

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), API_TIMEOUT);

  const response = await fetch(link, {
    body: method === "GET" ? null : body,
    method: method,
    credentials: "same-origin",
    headers,
    signal: controller.signal,
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      const sfmData = GetSFMDisplay({
        ShowMessage: true,
        Type: MessageTypes.SUCCESS,
        Major: {
          Constant: MAJ_SUCCESS_WARN,
        },
        Minor: [
          {
            MinorRetCodeDetails: [
              {
                ShortDescription: "timeout",
                LongDescription: "Request timed out",
              },
            ],
          },
        ],
      });
      SfmModalStore.open(sfmData, () => {});
      throw error; // Re-throw the error after logging it
    });
  clearTimeout(id);
  return response;
  // return fetch(link, {
  //   body: method === "GET" ? null : body,
  //   method: method,
  //   credentials: "same-origin",
  //   headers,
  // })
  //   .then((response) => {
  //     return response.json();
  //   })
  //   .catch((error) => {
  //     console.error("Failed to fetch:", error);
  //     throw error; // Re-throw the error after logging it
  //   });
};

export const apiHelper = async ({
  externalurl,
  filter = {
    enabled: false,
    value: [],
  },
  sort = {
    enabled: false,
    value: [],
  },
  limit = null,
  offset = 0,
  activityTag,
  extraBody = {},
  onlyBody = null,
  method = "POST",
  headerContentType = "application/json",
  serializeBody = true,
}) => {
  const ttpRequestId = require("uuid").v4();

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const apiKeySecret = process.env.REACT_APP_KEY_SECRET;

  const url = `${baseUrl}${externalurl}`;

  const { timeZone, ttpTenantCode, ttpUserId, languageCode } = GlobalStore;

  if (ttpTenantCode === null || ttpUserId === null) {
    return;
  }

  const body = onlyBody || {
    ...(filter.enabled && {
      FilteringProperties: filter.value ? filter.value : [],
    }),
    ...(sort.enabled && {
      SortingProperties: sort.value ? sort.value : [],
    }),
    Pagination: {
      PageIndex: offset,
      PageSize: limit,
    },
    ...extraBody,
  };

  const encoder = new TextEncoder();
  const shaData = encoder.encode(
    `${ttpTenantCode}${ttpUserId}${ttpRequestId}${apiKeySecret}`
  );

  const apiKey = await sha256Hash(shaData);

  const headers = {
    ...(headerContentType && { "Content-Type": headerContentType }),
    "Access-Control-Allow-Origin": "*",
    ...(headerContentType && { Accept: headerContentType }),
    TimeZone: timeZone,
    TTPRequestId: ttpRequestId,
    TTPTenantCode: ttpTenantCode,
    TTPUserId: ttpUserId,
    LanguageCode: languageCode,
    ActivityTag: activityTag,
    "x-apikey": apiKey,
    apiKey: apiKey,
  };
  const apiRequest = globalFetch(
    url,
    body,
    headerContentType,
    method,
    headers,
    serializeBody
  );
  // toast.promise(apiRequest, {
  //   pending: "Promise is pending",
  //   success: "Promise resolved 👌",
  //   error: "Promise rejected 🤯",
  // });
  return apiRequest;
};
