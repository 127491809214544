import React, { useEffect, useState } from "react";
import CustomTable from "@components/CustomTable";
import myStore from "./store";
import { Observer } from "mobx-react";
import { useTranslation } from "react-i18next";

import {
  fetchDynamicFilters,
  transformData,
  FormatWithCommas,
} from "@helpers/helpers";

import clsx from "clsx";

const InTransitListComponent = () => {
  const { t: translate, i18n } = useTranslation();

  const [dynamicFilters, setDynamicFilters] = useState([]);
  useEffect(() => {
    fetchDynamicFilters("InTransitFilters", (newFilters) => {
      setDynamicFilters(newFilters);
    });
  }, []);

  const getDynamicFilters = (key, onlyChildren = false) => {
    return transformData(
      (!!dynamicFilters &&
        dynamicFilters.length > 0 &&
        dynamicFilters.find((filter) => filter.ColumnName === key)?.Values) ||
        [],
      onlyChildren
    );
  };
  
  const reconciliationStreamTagDisplays = getDynamicFilters("ReconciliationStreamTag");

  const pinningInitialState = ["pin", "ReconciliationRunStatusName"];

  const columns = [
    {
      accessorKey: "ReconciliationStreamName",
      header: translate("in-transit-list.columns.ReconciliationStreamName"),
      canFilter: true,
      isMultiple: true,
      sortable: true,
      size: 230,
      truncate: true,
      processFilterValue: (value, row) => {
        return row.ReconciliationStreamTag;
      },
      processFilterKey: (value, row) => {
        return "ReconciliationStreamTag";
      },
      filterType: "checkbox",
      operator: "EQ",
    },
    {
      accessorKey: "AreaName",
      header: translate("in-transit-list.columns.AreaName"),
      processFilterValue: (value, row) => {
        return row.AreaId.toString();
      },
      processFilterKey: (value, row) => {
        return "AreaId";
      },
      size: 99,
      canFilter: true,
      isMultiple: true,
      sortable: true,
      truncate: false,
      filterType: "checkbox",
      operator: "EQ",
    },
    {
      accessorKey: "OutletName",
      header: translate("in-transit-list.columns.OutletName"),
      processFilterValue: (value, row) => {
        return row.OutletId.toString();
      },
      processFilterKey: (value, row) => {
        return "OutletId";
      },
      size: 99,
      canFilter: true,
      isMultiple: false,
      sortable: true,
      truncate: false,
      filterType: "string",
      operator: "EQ",
    },
    {
      accessorKey: "TransactionTerminalCode",
      header: translate("in-transit-list.columns.TransactionTerminalCode"),
      processFilterValue: (value, row) => {
        return row.TransactionTerminalCode;
      },
      processFilterKey: (value, row) => {
        return "TransactionTerminalCode";
      },
      size: 150,
      canFilter: true,
      sortable: true,
      filterType: "string",
      operator: "EQ",
    },
    {
      accessorKey: "RecordReference",
      header: translate("in-transit-list.columns.RecordReference"),
      size: 150,
      sortable: true,
      canPin: false,
      canFilter: true,
      filterType: "string",
      operator: "EQ",
    },
    {
      accessorKey: "RecordDate",
      header: translate("in-transit-list.columns.RecordDate"),
      size: 150,
      sortable: true,
      canPin: false,
      canFilter: true,
      filterType: "date",
    },
    {
      accessorKey: "RecordType",
      header: translate("in-transit-list.columns.RecordType"),
      size: 120,
      sortable: true,
      canPin: false,
      canFilter: false,
    },
    {
      accessorKey: "Attributes",
      header: translate("in-transit-list.columns.Attributes"),
      sortable: false,
      size: 180,
      displayInPopup: true,
      filterCheck: (value) => value.length > 0,
      cell: ({ row }) => {
        const column = row.getContext().column;
        const showValue = false;
        const isPinned = row.getIsPinned();
        const columnIsPinned = column.isPinned();
        const showTooltip = !showValue && !isPinned && !columnIsPinned;

        return (
          <div className="relative ">
            {!showValue && (
              <span
                className={clsx(
                  `w-full text-left inline-block cursor-pointer text-primary-400`,
                  showTooltip && "has-tooltip"
                )}
              >
                <span className="whitespace-nowrap">
                  {row.original.recordAttributes &&
                    `Expand ${row.original.recordAttributes.length} Attributes ⤵`}
                </span>
                {showTooltip && (
                  <span
                    className={clsx(
                      `tooltip rounded shadow-xl px-5 py-2`,
                      row.id < 10 ? "top-full" : "bottom-full mb-2",
                      `left-0 -translate-x-4 bg-white opacity-88 text-[#777f9e] z-[${
                        123 - row.id
                      }]`
                    )}
                  >
                    {row.original.recordAttributes &&
                      row.original.recordAttributes.map((attr, index) => (
                        <div
                          key={index}
                          className="flex flex-row justify-between gap-10 mt-[3px] mb-[3px]"
                        >
                          <span className="py-[2px] whitespace-nowrap text-sm font-semibold">
                            {attr.name}
                          </span>
                          <span className="py-[2px] px-[8px] whitespace-nowrap ltr:text-right rtl:text-left text-md">
                            {attr.value}
                          </span>
                        </div>
                      ))}
                  </span>
                )}
              </span>
            )}
            {showValue && (
              <div className="relative z-10 ltr:left-[10px] rtl:right-[10px] top-[20px]">
                <span className="w-full text-left block cursor-pointer mb-1 text-red-400">
                  Collapse Attributes ⤴
                </span>
                <div className="flex flex-col min-w-[264px] transition-all bg-primary-dark text-[#777f9e] text-14 rounded-lg px-[2px] py-[8px] mb-3 opacity-100">
                  {row.original.recordAttributes &&
                    row.original.recordAttributes.map((attr, index) => (
                      <div
                        key={index}
                        className="flex flex-row justify-between gap-2 mb-[2px]"
                      >
                        <span className="py-[2px] whitespace-nowrap text-xs font-semibold">
                          {attr.name}
                        </span>
                        <span className="py-[2px] px-[8px] whitespace-nowrap ltr:text-right rtl:text-left text-sm">
                          {attr.value}
                        </span>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
        );
      },
      popupCellDisplay: (value) => {
        return (
          <div className="relative text-primary-400 font-semibold">
            {value && value.length}
          </div>
        );
      },
      popupContent: (row) => {
        const value = row.Attributes;
        return (
          <div className="relative ">
            <div className="flex flex-col min-w-[264px] transition-all bg-primary-dark text-[#777f9e] text-14 rounded-lg px-[2px] py-[8px] mb-3 opacity-100">
              {value &&
                value.map((attr, index) => (
                  <div
                    key={index}
                    className="flex flex-row justify-between gap-2 mb-[2px]"
                  >
                    <span className="py-[2px] whitespace-nowrap text-xs font-semibold">
                      {attr.Key}
                    </span>
                    <span className="py-[2px] px-[8px] whitespace-nowrap ltr:text-right rtl:text-left text-sm">
                      {attr.Value}
                    </span>
                  </div>
                ))}
            </div>
          </div>
        );
      },
    },
    {
      accessorKey: "PaymentMethod",
      header: translate("in-transit-list.columns.PaymentMethod"),
      size: 145,
      sortable: false,
      canPin: true,
      canFilter: false,
    },
    {
      accessorKey: "Amount",
      header: translate("in-transit-list.columns.Amount"),
      canFilter: true,
      filterCheck: (value) => value > 0,
      isMultiple: false,
      size: 150,
      processFilterValue: (value, row) => {
        return row.Amount.toString();
      },
      cell: ({ row }) => {
        return (
            <span className="flex flex-col items-center justify-center group font-normal">
              <span>{`${row.original.Currency} ${FormatWithCommas(row.original.Amount)}`}</span>
            </span>
        );
      },
      filterType: "amount",
      operator: "EQ",
    },
    {
      accessorKey: "BusinessDay",
      header: translate("in-transit-list.columns.BusinessDay"),
      size: 150,
      sortable: true,
      canPin: false,
      canFilter: true,
      filterType: "date",
      cell: ({ row }) => {
        return (
          <span className="text-sm font-normal">
            {row.original.BusinessDay}
            <br />
            {row.original.BusinessDayInstance}
          </span>
        );
      },
    },
    {
      accessorKey: "InTransitDeadline",
      header: translate("in-transit-list.columns.InTransitDeadline"),
      size: 110,
      sortable: true,
      canPin: false,
      canFilter: false,
      cell: ({ row }) => {
        return (
          <span className="text-sm font-normal">
            {row.original.InTransitDeadline.split("T")[0]}
            <br />
            {row.original.InTransitDeadline.split("T")[1]}
          </span>
        );
      },
    },
    {
      accessorKey: "NumberOfAttempts",
      header: translate("in-transit-list.columns.NumberOfAttempts"),
      size: 130,
      cellAction: (value, context) => {
        window.location.href = `/in-transit-details?id=${context.row.original.PromotedRecordId}`;
      },
      cellActionCheck: (value) => value > 0,
    },
    {
      accessorKey: "PendingRecord",
      header: translate("in-transit-list.columns.PendingRecord"),
      size: 250,
      cellAction: (value, context) => {
        window.location.href = `/in-transit-details?id=${context.row.original.PromotedRecordId}`;
      },
      cell: ({ row }) => {
        return (
          <span className={`text-primary-400 `}>
            {row.original.PendingRecord}
          </span>
        );
      },
      truncate: true,
      cellActionCheck: (value, context) => context.row.original.NumberOfAttempts > 0,

    },
  ];

  const filterDisplayValues = {
    trigger: [
      {
        displayName: translate("filters-display.Manual"),
        value: "Manual",
      },
      {
        displayName: translate("filters-display.Schedule"),
        value: "Schedule",
      },
    ],
  };

  const filters = [
    {
      topFilters: [
        {
          components: [
            {
              name: translate("in-transit-list.filters.onlyCritical"),
              type: "switch",
              key: "IsInTransitCritical",
              filterType: "boolean",
              operator: "EQ",
            },
          ],
        },
      ],
      mainFilters: [
        {
          key: "ReconciliationStreamName",
          displayName: translate("in-transit-list.filters.stream"),
          icon: () => (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"
              />
            </svg>
          ),
          components: [
            {
              name: translate("in-transit-list.filters.stream"),
              type: "title",
              key: "title",
            },
            {
              name: translate("in-transit-list.filters.reconciliationRunId"),
              type: "numberInput",
              key: "ReconciliationRunId",
              placeHolder: translate("shared.filterRunID"),
              decimalPoint: 0,
              step: 1,
              operator: "EQ",
              filterType: "string",
              hasDecimal:false
            },
            {
              name: translate("in-transit-list.filters.recordReference"),
              type: "searchInput",
              key: "RecordReference",
              filterType: "string",
              operator: "EQ",
            },
            {
              name: translate("in-transit-list.filters.amount"),
              type: "numberInput",
              key: "Amount",
              filterType: "amount",
              inputType: "number",
            },
            {
              name: translate(
                "in-transit-list.filters.reconciliationStreamName"
              ),
              type: "checkboxGroup",
              key: "ReconciliationStreamTag",
              values: getDynamicFilters("ReconciliationStreamTag"),
              filterType: "checkbox",
              operator: "EQ",
            },
          ],
        },
        {
          key: "Location",
          displayName: translate("in-transit-list.filters.location"),
          icon: () => (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"
              />
            </svg>
          ),
          components: [
            {
              name: translate("in-transit-list.filters.location"),
              type: "title",
              key: "title",
            },
            {
              name: translate("in-transit-list.filters.area"),
              type: "checkboxGroup",
              key: "AreaId",
              values: getDynamicFilters("AreaId"),
              filterType: "checkbox",
              operator: "EQ",
              searchable: false,
            },
            {
              name: translate("in-transit-list.filters.outlet"),
              type: "checkboxGroup",
              key: "OutletId",
              values: getDynamicFilters("OutletId"),
              filterType: "checkbox",
              operator: "EQ",
            },
            {
              name: translate("in-transit-list.filters.terminal"),
              type: "checkboxGroup",
              key: "TransactionTerminalCode",
              values: getDynamicFilters("TransactionTerminalCode"),
              filterType: "checkbox",
              operator: "EQ",
            },
          ],
        },
        {
          key: "NextReconciliationRun",
          displayName: translate("in-transit-list.filters.date"),
          icon: () => (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
              />
            </svg>
          ),
          components: [
            {
              name: translate("in-transit-list.filters.selectDate"),
              type: "title",
              key: "title",
            },
            {
              name: translate("in-transit-list.filters.RecordDate"),
              type: "dateInput",
              inputType: "date",
              key: "RecordDate",
              placeHolder: translate("in-transit-list.filters.RecordDate"),
              prefix: "",
              filterType: "date",
            },
            {
              name: translate("in-transit-list.filters.businessDay"),
              type: "dateInput",
              inputType: "date",
              key: "BusinessDay",
              placeHolder: translate("in-transit-list.filters.businessDay"),
              prefix: "",
              filterType: "date",
            },
            {
              name: translate("in-transit-list.filters.DeadlineDate"),
              type: "dateInput",
              inputType: "date",
              key: "InTransitDeadline",
              placeHolder: translate("in-transit-list.filters.DeadlineDate"),
              prefix: "",
              filterType: "date",
            },
          ],
        },
      ],
    },
  ];

  const selectedFiltersDisplay = {
    direction: translate("filters-display.Direction"),
    ReconciliationStreamTag: translate("filters-display.StreamName"),
    TotalInTransitRecords: translate("filters-display.InTransitRecordID"),
    TotalExceptionRecords: translate("filters-display.ExceptionRecordID"),
    TotalMatchingRecords: translate("filters-display.MatchingRecordID"),
    TotalReconciledRecords: translate("filters-display.ReconciledRecordID"),
    NextReconciliationRun: translate("filters-display.NextRun"),
    StartDate: translate("filters-display.StartDate"),
    EndDate: translate("filters-display.EndDate"),
    ScheduleDate: translate("filters-display.ScheduleDate"),
    contains: translate("filters-display.Contains"),
    startWith: translate("filters-display.StartsWith"),
    endWith: translate("filters-display.EndsWith"),
    IsScheduled: translate("filters-display.Scheduled"),
    ReconciliationRunStatusTag: translate("filters-display.StreamStatus"),
    Trigger: translate("filters-display.Trigger"),
    RunID: translate("filters-display.RunID"),
    BusinessDay: translate("filters-display.BusinessDay"),
    AreaId: translate("filters-display.Area"),
    OutletId: translate("filters-display.Outlet"),
    TransactionTerminalCode: translate("filters-display.Terminal"),
    RecordReference: translate("filters-display.RecordReference"),
    RecordDate: translate("filters-display.RecordDate"),
    ReconciliationRunId: translate("filters-display.RunID"),
    IsInTransitCritical: translate("filters-display.OnlyCriticalRecords"),
    InTransitDeadline: translate("filters-display.DeadlineDate"),
  };

  return (
    <Observer>
      {() => {
        return (
          <CustomTable
            store={myStore}
            columnsWithoutPin={columns}
            loading={myStore.loading}
            pinCondition={(row) => {
              return row.IsScheduled;
            }}
            filterDisplayValues={filterDisplayValues}
            selectedFiltersDisplay={selectedFiltersDisplay}
            filters={filters}
            pinningInitialState={pinningInitialState}
            setParentSelectedValues={() => {}}
            actions={{}}
            prefix="InTransit"
            // title={translate('in-transit.title')}
            title={translate("in-transit.title")}
            pinnedRowHeight={57}
            showExportButton={true}
            activityTag="ExportInTransitRecords"
            hideActionColumn={true}
            language={i18n.language}
            maxTableHeight={'calc(100dvh - 400px)'}
            externalDisplays={reconciliationStreamTagDisplays}
          />
        );
      }}
    </Observer>
  );
};

export default InTransitListComponent;
