import React, { Component } from "react";

import { Observer } from "mobx-react";
import ReportsModalStore from "@stores/ReportsModalStore";
import clsx from "clsx";
import ReportsComponent from "@/components/ReportsComponent";
import { useTranslation } from "react-i18next";

const ReportsModal = () => {
  const { t: translate, i18n } = useTranslation();

  return (
    <Observer>
      {
        () => {
          let reports = [];
          try {
            reports = JSON.parse(localStorage.getItem("reports") || "[]");
          } catch (error) {}

          return (
            // ReportsModalStore.showing && (
            <div
              className={clsx(
                `group`,
                ReportsModalStore.showing ? "visible" : "invisible"
              )}
            >
              <div
                className="fixed top-0 left-0 w-full h-full bg-[#000000] bg-opacity-20 z-[25]"
                onClick={() => ReportsModalStore.close()}
              />
              <div
                className={clsx(
                  `fixed transition-all overflow-y-scroll h-dvh duration-500 ease-in-out z-50 bg-white text-black py-6 px-5 top-0`,
                  ReportsModalStore.showing
                    ? "ltr:right-0 rtl:left-0  min-w-[300px]"
                    : "ltr:-right-36 rtl:-left-36  min-w-0"
                )}
              >
                <div className="flex flex-col gap-4">
                  <div className="flex gap-3 justify-center items-center">
                    <div className="w-full font-bold">
                      {" "}
                      {ReportsModalStore.title}
                    </div>
                    <button
                      onClick={() => ReportsModalStore.close()}
                      className="w-full justify-end flex"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="size-8 hover:text-red-800 transition-all duration-200"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18 18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                  {reports.length > 0 ? (
                    <ReportsComponent reports={reports} key={`reportsComponent-${ReportsModalStore.showing}`} />
                  ) : (
                    <div className="text-center text-primary-700 text-sm mt-5">
                      {translate('reports.no-reports')}
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        }
        // )
      }
    </Observer>
  );
};
export default ReportsModal;
