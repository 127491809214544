const calculateAttributeColor = (attr) => {
  let attributeColor = "text-gray-700";
  if (attr.Tag === "VARIANCE_AMOUNT") {
    attributeColor = attr?.IsNegative ? "text-red-500" : "text-green-500";
  }
  return `<span class="${attributeColor}">${attr.Value}</span>`;
};

export const analyzeData = (
  response,
  showPopup = () => {},
  uniqueID,
  maxSeriesPerPage
) => {
  const chartSettings = response.Chart;
  const dataSets = response.Chart.DataSets;
  const legends = response.Chart.Legends;

  // Extract X-axis categories
  const categories = chartSettings.XAxis.ScaleMarkers.map(
    (marker) => marker.Label
  );

  const pointClick = (event, chartContext, { seriesIndex, dataPointIndex }) => {
    const dataSet = dataSets[seriesIndex];
    const dataPoint = dataSet?.Data[dataPointIndex];
    const popup = dataPoint?.Popup;

    if (popup) {
      const label = popup?.Label || "";
      const description = popup?.ShortDescription || "";
      let popupContent = PopUpBuilder({
        label: label,
        description: description,
        attributes: popup.Attributes,
      });

      // Display the popup content (you can use a modal or any other method to display the content)
      // alert(popupContent);
      showPopup(popupContent, seriesIndex, dataPointIndex);
    } else {
      // showPopup(null);
    }
  };
  // Generate chartOptions
  const hideLabels = window.innerWidth <= 1300;

  const chartOptions = {
    chart: {
      type: "line",
      height: 350,
      zoom: {
        enabled: false, // Disable range zooming
      },
      animations: {
        enabled: true,
        easing: "easeinout",
        // speed: 1800,
        animateGradually: {
          enabled: true,
          delay: 250,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 750,
        },
      },
      events: {
        markerClick: function (
          event,
          chartContext,
          { seriesIndex, dataPointIndex }
        ) {
          pointClick(event, chartContext, { seriesIndex, dataPointIndex });
        },
        click: function (event, chartContext, { seriesIndex, dataPointIndex }) {
          pointClick(event, chartContext, { seriesIndex, dataPointIndex });
        },
        legendClick: function (chartContext, seriesIndex, config) {
          const series = chartContext.w.globals.series;
          const seriesName = chartContext.w.globals.seriesNames[seriesIndex];
          const isVisible =
            chartContext.w.globals.series[seriesIndex].length > 0;
          console.log("test", seriesName, isVisible);
          if (isVisible) {
            chartContext.hideSeries(seriesName);
          } else {
            chartContext.showSeries(seriesName);
          }
        },
        xAxisLabelClick: function (
          pointerEvent,
          opts,
          { seriesIndex, labelIndex }
        ) {
          const marker = chartSettings.XAxis.ScaleMarkers[labelIndex];

          const popup = marker.Popup;
          if (popup) {
            const label = popup?.Label || "";
            const description = popup?.ShortDescription || "";
            let popupContent = PopUpBuilder({
              label: label,
              description: description,
              attributes: popup.Attributes,
            });

            // Display the popup content
            showPopup(
              popupContent,
              seriesIndex,
              labelIndex,
              chartSettings.XAxis.ScaleMarkers
            );
          }
        },
      },
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
        export: {
          csv: {
            filename: chartSettings.Label,
            columnDelimiter: ",",
          },
          svg: {
            filename: chartSettings.Label,
          },
          png: {
            filename: chartSettings.Label,
          },
        },
        // autoSelected: 'zoom'
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "30%", // Narrow down the width of the bars
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: !hideLabels,
      enabledOnSeries: dataSets
        .map((set, index) =>
          set.ElementTag === "LINE" || set.ElementTag === "BAR" ? index : null
        )
        .filter((index) => index !== null), // Enable data labels only for the line series
      offsetX: 0,
      offsetY: -5, // Adjust the vertical position of the data labels
      style: {
        fontSize: "12px",
        // fontFamily: "Helvetica, Arial, sans-serif",
        fontFamily: "Inter",
        fontWeight: "normal",
        colors: ["#ffffff99"],
        cursor: "pointer",
      },
      background: {
        enabled: true,
        foreColor: "black",
        padding: 2,
        borderRadius: 0,
        borderWidth: 0,
        borderColor: "#d6d6d6",
        opacity: 1,
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          // color: "#000",
          opacity: 0.45,
        },
      },
      dropShadow: {
        enabled: false,
        top: 0,
        left: 0,
        blur: 1,
        color: "black",
        opacity: 0.3,
      },
      formatter: function (val, opts) {
        const seriesIndex = opts.seriesIndex;
        const dataPointIndex = opts.dataPointIndex;
        const dataSet = dataSets[seriesIndex];
        const dataPoint = dataSet.Data[dataPointIndex];
        return dataSet.ShowDataLabel
          ? dataPoint?.Label || ""
          : dataSet.ShowDataValue
          ? dataPoint?.Value || ""
          : "";
      },
    },
    stroke: {
      show: true,
      width: dataSets.map((set) => (set.ElementTag === "LINE" ? 3 : 0)),
      // curve: "smooth", // Make the lines straight without border radius
      curve: "smooth", // Make the lines straight without border radius
    },
    markers: {
      strokeColors: "#444343",
      strokeWidth: 2,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      size: 5, // Size of the markers
      // shape: "triangle", // Shape of the markers
      // shape: "cross", // Shape of the markers
      shape: "circle", // Shape of the markers
      hover: {
        size: 7, // Size of the markers on hover
        sizeOffset: 3, // Offset of the markers on hover
      },
    },
    // colors: dataSets.map((set) => set.ElementColor), // Use colors from the dataset
    colors: legends.map((set) => set.KeyColor), // Use colors from legends
    title: {
      text: chartSettings.Label,
      align: "center",
      margin: 30,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: "22px",
        // fontWeight: "bold",
        fontFamily: undefined,
        color: "#263238",
      },
    },
    xaxis: {
      categories: categories,
      title: {
        text: chartSettings.XAxis.Label,
        offsetY: -5,
        style: {
          color: "#263238",
          fontSize: "12px",
          // fontFamily: "Helvetica, Arial, sans-serif",
          fontFamily: "Inter",
          fontWeight: 500,
          cssClass: "font-semibold text-md",
        },
      },
      // reversed: true,
      labels: {
        style: {
          fontSize: "12px",
          // fontFamily: "Helvetica, Arial, sans-serif",
          fontFamily: "Inter",
          // fontWeight: "bold",
          fontWeight: 500,
          colors: undefined,
          cssClass: "cursor-pointer",
        },
        formatter: function (value) {
          return hideLabels
            ? value && value.length > 10
              ? `${value.substring(0, 10)}..`
              : value
            : value;
        },
      },
    },
    yaxis: chartSettings.YAxis.map((axis) => ({
      title: {
        text: axis.Label,
      },
      max: axis.Tag.indexOf("PERCENTAGE") > -1 ? 100 : undefined,
      opposite: axis.Position === "RIGHT",
    })),
    fill: {
      opacity: [1, 0.8, 0.8],
    },
    tooltip: {
      shared: false,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const currentPage = document.getElementById(
          `current_page_${uniqueID}`
        ).value;
        const calculatedDataPointIndex =
          currentPage * maxSeriesPerPage + dataPointIndex;
        const dataPoint = dataSets[seriesIndex].Data[calculatedDataPointIndex];
        if (dataPoint.Tooltip) {
          const label = dataPoint?.Tooltip?.Label || "";
          const description = dataPoint?.Tooltip?.ShortDescription || "";
          let tooltipContent = `<div class="tooltip-content px-2 py-3">
                                  <h3><b><u>${label}</u></b></h3>
                                  <p>${description}</p>
                                  <ul class="mt-2 gap-1">`;
          dataPoint.Tooltip.Attributes.forEach((attr) => {
            tooltipContent += `<li>
                                <strong>${attr.Label}:</strong> 
                                ${calculateAttributeColor(attr)}
                              </li>`;
          });
          tooltipContent += `</ul></div>`;
          return PopUpBuilder({
            label: label,
            description: description,
            attributes: dataPoint.Tooltip.Attributes,
            parentClass: "tooltip-content px-2 py-3",
          });
        }
        return "";
      },
    },
    legend: {
      position: "bottom", // Move the legend to the bottom
      horizontalAlign: "center", // Center align the legend
      offsetY: 10,
      itemMargin: {
        horizontal: 5,
        vertical: 5,
      },

      formatter: function (seriesName, opts) {
        return [
          `<strong>${legends[opts.seriesIndex]?.Label || seriesName}</strong>`,
        ];
      },
    },
    grid: {
      show: true,
      borderColor: "#d6d6d680",
      strokeDashArray: 1,
      position: "back",
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        top: 0,
        right: hideLabels ? 0 : 50,
        bottom: 10,
        left: hideLabels ? 0 : 50,
      },
    },
  };

  // Generate chartSeries
  const chartSeries = dataSets.map((set) => {
    const reversedSet = set.Data.reverse();
    return {
      name: set.Label,
      type: set.ElementTag.toLowerCase(),
      data: reversedSet.map((dataPoint) => dataPoint.Value),
      originalData: reversedSet,
    };
  });

  return { chartOptions, chartSeries };
};

export const PopUpBuilder = ({
  label = "",
  description = "",
  attributes,
  parentClass = "popup-content",
}) => {
  let popupContent = `<div class="${parentClass}">
                        <h3><b><u>${label}</u></b></h3>
                        <p>${description}</p>
                        <ul class="mt-2 gap-1">`;
  attributes.forEach((attr) => {
    popupContent += `<li>
                        <strong>${attr.Label}:</strong> 
                        ${calculateAttributeColor(attr)}
                      </li>`;
  });
  popupContent += `</ul></div>`;
  return popupContent;
};
