import ListingStore from "@stores/ListingStore";
import { apiHelper } from "@helpers/apiHelper";
import SfmModalStore from "@stores/SfmModalStore";
import { GetSFMDisplay, isSfmSuccess } from "@helpers/helpers";

const STORE_LIMIT = 50;

class Store extends ListingStore {
  microserviceInput = "";
  dataKey = "RunRecords";
  limit = STORE_LIMIT;
  offset = 0;
  setMicroservice(microservice) {
    this.microserviceInput = microservice;
  }

  setLoading(loading) {
    this.loading = loading;
  }

  matchingResultTag = "";
  reconciliationRunID = "";

  async getData() {
    const externalurl = `/api/v1/ControlRoom/GetRunMatchingRecords`;
    const activityTag = "GetRunMatchingRecords";

    const mandatoryFilters = [
      {
        ColumnName: "MatchingResultTag",
        Value: this.matchingResultTag,
        Operand: "EQ",
      },
      {
        ColumnName: "ReconciliationRunId",
        Value: this.reconciliationRunID,
        Operand: "EQ",
      },
    ];

    const sentFilters =
      !!this.filter && this.filter.length > 0
        ? [...mandatoryFilters, ...this.filter[0]]
        : [...mandatoryFilters];

    const apiRequest =
      this.matchingResultTag !== "" &&
      this.reconciliationRunID !== "" &&
      apiHelper({
        externalurl,
        activityTag,
        filter: {
          enabled: true,
          value: [sentFilters],
        },
        sort: {
          enabled: true,
          value: this.sort,
        },
        limit: this.limit,
        offset: this.offset,
      });
    return (
      !!apiRequest &&
      apiRequest
        .then((data) => {
          const sfmData = GetSFMDisplay(data.SFM);
          if (!isSfmSuccess(sfmData)) {
            SfmModalStore.open(sfmData);
            return {
              data: [],
              stats: [],
              total: 0,
              page: 1,
            };
          } else {
            return {
              data: data.RunRecords,
              stats: data.RunStatistics,
              total: data.RunRecords.length, // You might need to adjust this depending on the actual response structure
              page: 1, // Adjust if pagination data is available in the response
            };
          }
        })

        .catch((error) => {
          return;
        })
    );
  }
}

const myStore = new Store();

export default myStore;
