import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import GlobalStore from "@stores/GlobalStore";
import Loader from "@components/LoaderComponent/loader";
import Show from "@components/Icons/Show";
import Hide from "@components/Icons/Hide";
import LanguageSelector from "@components/Icons/LanguageSelector";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import ReportsModalStore from "@stores/ReportsModalStore";
import { GetReportTypes } from "@/helpers/helpers";
import TailwindDropdown from "@components/TailwindDropdown";

const LoginPage = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [tenant, setTenant] = useState("2222");
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const { t: translate, i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language || "en");

  const validate = () => {
    const errors = {};
    if (!username) errors.username = translate("login.username-required");
    if (!password) errors.password = translate("login.password-required");
    return errors;
  };

  const tenants = [
    { key: "2222", value: "2222" },
    { key: "6666", value: "6666" },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors({});
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setIsSubmitting(true);
    // Simulate login API call
    setTimeout(() => {
      if (username === "admin" && password === "admin") {
        // call GetReportTypes api
        setTimeout(() => {
          GetReportTypes((response) => {
            ReportsModalStore.setLocalStorageReports(response.ReportTypes);
          });
        }, 1000);

        GlobalStore.setGlobalSettings({
          ttpUserId: "2222_USER_1",
          languageCode: language,
          ttpTenantCode: tenant,
          callback: () => {
            setTimeout(() => {
              const lastLocation = localStorage.getItem("lastLocation");
              localStorage.removeItem("lastLocation");
              navigate(lastLocation || "/"); // Redirect to the home page after login
            }, 500);
          },
        });
        // navigate("/");
      } else {
        setErrors({ submit: translate("login.invalid-credentials") });
      }
      setIsSubmitting(false);
    }, 1000);
  };

  const handleLanguageChange = () => {
    const selectedLanguage = language === "en" ? "ar" : "en";
    setLanguage(selectedLanguage);
    setErrors({});
    // GlobalStore.setGlobalSettings({
    //   languageCode: selectedLanguage,
    //   callback: () => {
    //     setTimeout(() => {
    //       i18n.changeLanguage(selectedLanguage);
    //     }, 500);
    //   },
    // });

    i18n.changeLanguage(selectedLanguage);
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div
        className={clsx(
          "w-full max-w-md p-8 space-y-8 bg-white rounded shadow-md",
          errors.submit && "animate-jiggle",
          isSubmitting && "animate-pulse"
        )}
      >
        <span className="text-lg font-semibold text-center w-full block text-gray-400">
          {translate("login.intro")}
        </span>
        <div className="flex items-center justify-between gap-5">
          <h2 className="text-xl font-bold ">{translate("login.title")}</h2>
          <div className="flex gap-2">
            <label className="flex mt-6 text-sm font-medium text-gray-700">
              {translate("login.tenant")}
            </label>
            <TailwindDropdown
              data={tenants}
              selectedValue={tenant}
              setActiveTab={setTenant}
            />
          </div>
        </div>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className={errors.username && "animate-jiggle"}>
            <label
              htmlFor="username"
              className="block text-sm font-medium text-gray-700"
            >
              {translate("login.username")}
            </label>
            <input
              id="username"
              name="username"
              type="text"
              onChange={(e) => setUsername(e.target.value)}
              value={username}
              className="w-full px-3 py-2 mt-1 border rounded shadow-sm focus:ring-primary-500 focus:border-primary-500"
            />
            {errors.username && (
              <div className="mt-2 text-sm text-red-600">{errors.username}</div>
            )}
          </div>
          <div className={errors.password && "animate-jiggle"}>
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700"
            >
              {translate("login.password")}
            </label>
            <div
              key={`showPassword-${showPassword}`}
              className="w-full px-3 py-2 mt-1 border rounded shadow-sm focus:ring-primary-500 focus:border-primary-500 flex gap-2"
            >
              <input
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                className="w-full outline-none"
              />
              {showPassword ? (
                <Show
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                />
              ) : (
                <Hide
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                />
              )}
            </div>
            {errors.password && (
              <div className="mt-2 text-sm text-red-600">{errors.password}</div>
            )}
          </div>

          {errors.submit && (
            <div className="mt-2 text-sm text-red-600">{errors.submit}</div>
          )}
          <div className="flex justify-between items-center">
            <button
              type="submit"
              disabled={isSubmitting}
              className="w-content flex gap-2 items-center justify-center px-4 py-2 font-medium text-white bg-primary-600 rounded hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
            >
              {isSubmitting
                ? translate("login.logging-in")
                : translate("login.login")}
              {isSubmitting && <Loader size="5" />}
            </button>
            <a href="#" className="text-sm text-primary-600 hover:underline">
              {translate("login.forgot-password")}
            </a>
          </div>
        </form>
      </div>
      <div
        className={clsx(
          "fixed bottom-2",
          language === "ar" ? "right-2" : "left-2"
        )}
      >
        <LanguageSelector
          currentLanguage={language}
          handleChangeLanguage={handleLanguageChange}
          withAnimation={true}
        />
      </div>
    </div>
  );
};

export default LoginPage;
