/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import DownloadModalStore from "@stores/DownloadModalStore";
import InfoModalStore from "@stores/InfoModalStore";
import GlobalStore from "@stores/GlobalStore";
import ReportsModalStore from "@stores/ReportsModalStore";

import SideBarItem from "./SideBarItem";
import clsx from "clsx";
import LogoutIcon from "@components/Icons/Logout";
import LanguageSelector from "@components/Icons/LanguageSelector";
import TooltipComponent from "@components/TooltipComponent";

import { Observer } from "mobx-react";

const getDefaultMode = () => {
  const savedDarkMode = localStorage.getItem("darkMode");
  if (savedDarkMode !== null) {
    return JSON.parse(savedDarkMode);
  }
  return true;
};

const Sidebar = ({ menus }) => {
  
  const [open, setOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [downloadCenterState, setDownloadCenterState] = useState(false);
  const [darkMode, setDarkMode] = useState(getDefaultMode());
  const [openSubMenus, setOpenSubMenus] = useState({});
  const location = useLocation();

  const {
    t: translate,
    i18n: { changeLanguage, language },
    i18n,
    ready,
  } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(language);

  const [refreshComponent, setRefreshComponent] = useState(false);
 


  useEffect(() => {
    localStorage.setItem("darkMode", JSON.stringify(darkMode));
  }, [darkMode]);

  useEffect(() => {
    const currentPath = location.pathname;
    console.log("currentPath", currentPath);
    const activeMenuIndex = menus.findIndex(
      (menu) =>
        menu.path === currentPath || menu.includedPaths?.includes(currentPath)
    );
    if (activeMenuIndex !== -1) {
      setActiveIndex(activeMenuIndex);
    }
  }, [location, menus]);

  const handleChangeLanguage = () => {
    const newLanguage = currentLanguage === "en" ? "ar" : "en";
    setCurrentLanguage(newLanguage);
    changeLanguage(newLanguage);
    window.location.reload();
  };

  const handleClickOutside = () => {
    setOpen(false);
    setOpenSubMenus({});
  };

  const renderLangSelector = () => {
    return (
      <LanguageSelector
        currentLanguage={currentLanguage}
        handleChangeLanguage={handleChangeLanguage}
      />
    );
    const isArabic = currentLanguage === "ar";
    const isEnglish = currentLanguage === "en";
    return (
      <div className="text-white text-center w-full ">
        {/* <h3>{currentLanguage}</h3> */}
        <button
          type="button"
          className="cursor-pointer pb-2"
          onClick={handleChangeLanguage}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="64"
            height="64"
            viewBox="0 0 64 64"
            direction="ltr"
            className="group transition-transform duration-500"
            key={`lang_switcher_${currentLanguage}`}
          >
            <image
              x="5"
              y="15"
              width="20"
              height="20"
              href={require("@assets/uk-flag.png")}
              className={clsx(
                "transition-all origin-left duration-500",
                !isEnglish
                  ? "group-hover:scale-150 group-hover:filter-none grayscale"
                  : "group-hover:grayscale  group-hover:scale-75"
              )}
              fontFamily="Arial, sans-serif"
              fontSize={isEnglish ? "26" : "22"}
              fontWeight={isEnglish ? "800" : "400"}
              textAnchor="middle"
            />
            {/* 🇬🇧
            </text> */}

            <image
              x="37"
              y="30"
              width="20"
              height="20"
              href={require("@assets/saudi-flag.png")}
              className={clsx(
                "transition-all origin-right duration-500",
                !isArabic
                  ? "group-hover:scale-150 group-hover:filter-none grayscale"
                  : "group-hover:grayscale group-hover:scale-75"
              )}
              fontFamily="Arial, sans-serif"
              fontSize={isArabic ? "28" : "22"}
              fontWeight={isArabic ? "800" : "400"}
              textAnchor="middle"
            />
            {/* 🇸🇦
            </text> */}

            {/* <path
              className="group-hover:stroke-[transparent]"
              d="M35,15 L28,45"
              stroke="#6b728020"
              strokeWidth="2"
            /> */}
          </svg>

          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            viewBox="0 0 64 64"
            direction="ltr"
            className="group transition-transform duration-500"
            key={`lang_switcher_${currentLanguage}`}
          >
            <text
              x="12"
              y="35"
              className={clsx(
                "transition-all origin-center duration-500",
                !isEnglish
                  ? "group-hover:fill-[#f44336] group-hover:scale-125"
                  : "group-hover:fill-[#878787]"
              )}
              fontFamily="Arial, sans-serif"
              fontSize="24"
              fontWeight={isEnglish ? "800" : "400"}
              fill={isEnglish ? "#f44336" : "#878787"}
            >
              A
            </text>
            <text
              x="42"
              y="45"
              className={clsx(
                "transition-all origin-center duration-500",
                !isArabic
                  ? "group-hover:fill-[#4caf50] group-hover:scale-125"
                  : "group-hover:fill-[#878787]"
              )}
              fontFamily="Arial, sans-serif"
              fontSize="24"
              fontWeight={isArabic ? "800" : "400"}
              fill={isArabic ? "#4caf50" : "#878787"}
            >
              ع
            </text>

            <path
              d="M45,11 L28,55"
              stroke="#2196f3"
              strokeWidth="2"
              fill="none"
            />
          </svg> */}
        </button>
      </div>
    );
  };

  const renderDownloadCenterIcon = () => {
    return (
      <div className="text-white text-center w-full ">
        <button
          type="button"
          className={clsx(
            "cursor-pointer grayscale hover:grayscale-0",
            DownloadModalStore.showing && "grayscale-0"
          )}
          onClick={() => {
            setOpen(false);
            ReportsModalStore.close();
            InfoModalStore.setShowing(false);
            // DownloadModalStore.open(!DownloadModalStore.setShowing());
            DownloadModalStore.setShowing(!DownloadModalStore.showing);
          }}
          title="Download Center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 64 64"
            direction="ltr"
            className="group transition-transform duration-500"
            key={`download_center`}
          >
            <rect
              x="8"
              y="20"
              width="48"
              height="32"
              rx="4"
              ry="4"
              fill="#ffeb3b"
              stroke="#fbc02d"
              strokeWidth="2"
            />
            <rect
              x="8"
              y="16"
              width="24"
              height="8"
              rx="2"
              ry="2"
              fill="#ffeb3b"
              stroke="#fbc02d"
              strokeWidth="2"
            />

            <path
              d="M32,26 L32,42"
              stroke="#2196f3"
              strokeWidth="6"
              fill="none"
            />
            <path d="M26,36 L32,42 L38,36" fill="#2196f3" />

            <rect x="22" y="42" width="20" height="4" fill="#2196f3" />
          </svg>
        </button>
      </div>
    );
  };

  const Logout = () => {
    localStorage.removeItem("ttpUserId");
    localStorage.removeItem("reports");
    GlobalStore.setGlobalSettings({
      ttpUserId: null,
      callback: () => {
        setTimeout(() => {
          window.location.href = "/login";
        }, 500);
      },
    });
  };

  const renderReports = () => {
    let reports = [];
    try {
      if (localStorage.getItem("reports")) {
        reports = JSON.parse(localStorage.getItem("reports"));
      }
    } catch (error) {
      console.error("Failed to parse reports from localStorage:", error);
    }

    const reportsMenu = {
      title: "reports",
      tinyTitle: "",
      svgIcon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 64 64"
        >
          <rect x="14" y="12" width="36" height="8" fill="#4caf50" />
          <rect x="14" y="24" width="36" height="8" fill="#81c784" />
          <rect x="14" y="36" width="36" height="8" fill="#c8e6c9" />

          <rect x="20" y="48" width="4" height="12" fill="#388e3c" />
          <rect x="28" y="44" width="4" height="16" fill="#66bb6a" />
          <rect x="36" y="40" width="4" height="20" fill="#a5d6a7" />
        </svg>
      ),
      isReports: true,
    };
    const reportsIndex = menus.length;
    return reports?.length > 0 ? (
      <SideBarItem
        key={`sidebar_${reportsIndex}`}
        index={reportsIndex}
        Menu={reportsMenu}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
        open={open}
        setOpenSubMenus={setOpenSubMenus}
        openSubMenus={openSubMenus}
        setOpen={setOpen}
        darkMode={darkMode}
      />
    ) : (
      <></>
    );
    // return (
    //   <SideBarItem
    //     key={`sidebar_${reportsIndex}`}
    //     index={reportsIndex}
    //     Menu={reportsMenu}
    //     activeIndex={activeIndex}
    //     setActiveIndex={setActiveIndex}
    //     open={open}
    //     setOpenSubMenus={setOpenSubMenus}
    //     openSubMenus={openSubMenus}
    //     setOpen={setOpen}
    //     darkMode={darkMode}
    //   />
    // )
  };

  return (
    <Observer>
      {() => {
        return (
          <div
            className={clsx(
              `flex fixed  h-full`,
              open ? "z-[2001] w-full" : "z-[100]"
            )}
          >
            <div
              onClick={handleClickOutside}
              className={clsx(
                `flex absolute top-0 left-0`,
                open && " w-full h-full bg-gray-400 z-[-1] bg-opacity-70"
              )}
            ></div>
            <div>
              <div
                className={clsx(
                  `absolute  h-dvh duration-300 flex flex-col justify-between cursor-pointer`,
                  darkMode ? "bg-primary-800" : "bg-gray-100",
                  open
                    ? "w-full md:w-80 bg-blend-overlay p-5 pt-8 "
                    : "w-[90px] p-5 pb-1 pt-8",
                  (open && darkMode) && "bg-primary-700",
                  (open && !darkMode) && "bg-gray-100",
                  (!darkMode && !open) && "border-r-2 border-gray-200"
                  
                )}
           
              >
                <img
                  src={require("@assets/control.png")}
                  className={clsx(
                    `absolute ltr:-right-0 ltr:md:-right-3 rtl:-left-3 rtl:rotate-180 top-9 w-7 border-primary-900 bg-white border-2 rounded-full z-[2] cursor-pointer`,
                    !open && "ltr:rotate-180 rtl:rotate-0 ltr:!-right-3"
                  )}
                  onClick={() => {
                    setOpenSubMenus({});
                    setOpen(!open);
                  }}
                  alt="cursor-icon"
                />
                <div className="flex justify-between pr-5 rtl:pl-5">
                  <div
                    className={clsx(
                      "flex gap-x-4 items-center",
                      
                      !darkMode ? "brightness-75" : ""
                    )}
                   
                  >
                    <img
                      src={require("@assets/pluto.png")}
                      className={clsx(
                        `cursor-pointer w-9 h-9 duration-[20s]`,
                        open && "rotate-[360deg]",
                      )}
                      alt="main-icon"
                      onClick={() => {
                        setDarkMode(!darkMode);
                      }}
                      data-tooltip-id="tooltip-darkmode"
                    />
                    <h1
                      className={clsx(
                        `origin-left font-medium text-2xl duration-200`,
                        !open && "scale-0",
                        darkMode ? "text-white" : "text-primary-900"
                      )}
                    >
                      Pluto
                    </h1>
                  </div>
                  <TooltipComponent
                    anchorElement="tooltip-darkmode"
                    toolTipMessage={translate(
                      "menu.darkMode"
                    )}
                    position="bottom-end"
                    className="max-w-96"
                  />
                  {open && (
                    <div
                      onClick={() => Logout()}
                      className={clsx(
                        "flex items-center text-center justify-center gap-2 cursor-pointer hover:text-red-600",
                        darkMode ? "text-white" : "text-primary-900"
                      )}
                    >
                      <span className="text-xs">
                        {translate("login.logout")}
                      </span>
                      <LogoutIcon className="w-4 h-4 " />
                    </div>
                  )}
                </div>

                <ul className="pt-6 ">
                  {menus.map((Menu, index) => (
                    <SideBarItem
                      key={`sidebar_${index}`}
                      index={index}
                      Menu={Menu}
                      activeIndex={activeIndex}
                      setActiveIndex={setActiveIndex}
                      open={open}
                      setOpenSubMenus={setOpenSubMenus}
                      openSubMenus={openSubMenus}
                      setOpen={setOpen}
                      darkMode={darkMode}
                    />
                  ))}
                  {renderReports()}
                </ul>

                <div className="relative flex flex-col gap-1">
                  <div className={clsx("flex", open ? "flex-row" : "flex-col")}>
                    {renderDownloadCenterIcon()}
                    {renderLangSelector()}
                  </div>
                  {open && (
                    <span
                      className={clsx(
                        "cursor-help absolute -bottom-4 z-[0] text-xs  w-full text-center",
                        darkMode ? "text-white" : "text-primary-900"
                      )}
                      onClick={() => {
                        navigator.clipboard.writeText(VERSION || "");
                      }}
                    >
                      {VERSION || ""}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </Observer>
  );
};

export default Sidebar;
