import { apiHelper } from "@helpers/apiHelper";
import { analyzeData, PopUpBuilder } from "@helpers/chartsHelpers";
import { makeObservable, observable, action } from "mobx";

const STORE_LIMIT = 10;
const DEFAULT_MAX_SERIES_PER_PAGE = 5;

class Store {
  data = {};
  microserviceInput = "";
  dataKey = "ChartMetrics";
  popupData = null;
  ChartTag = "";
  ChartTypeTag = "";
  ScaleTag = "";
  uniqueID = "";
  loading = true;
  filter = [];
  maxSeriesPerPage = DEFAULT_MAX_SERIES_PER_PAGE;
  currentPage = 0;
  error = null;

  constructor() {
    this.limit = STORE_LIMIT;
    this.offset = 0;
    this.showMore = true;
    makeObservable(this, {
      data: observable,
      microserviceInput: observable,
      filter: observable,
      loading: observable,
      popupData: observable,
      ChartTag: observable,
      ChartTypeTag: observable,
      ScaleTag: observable,
      uniqueID: observable,
      setMicroservice: action,
      setChartParams: action,
      paginateSeries: action,
      convertData: action,
      getData: action,
      fillData: action,
      setData: action,
      setLoading: action,
      setAppliedFilters: action,
      maxSeriesPerPage: observable,
      currentPage: observable,
      setCurrentPage: action,
    });
  }

  setCurrentPage(page) {
    this.currentPage = page;
  }

  handleNextPage() {
    this.setCurrentPage(this.currentPage + 1);
  }

  handlePreviousPage() {
    if (this.currentPage > 0) {
      this.setCurrentPage(this.currentPage - 1);
    }
  }

  setMicroservice(microservice) {
    this.microserviceInput = microservice;
  }

  setLoading(loading) {
    this.loading = loading;
  }

  setData(data) {
    this.data = data;
    console.log(data?.paginatedChartSeries?.length);
    if (data?.paginatedChartSeries?.length !== 0) {
      this.setCurrentPage(data.paginatedChartSeries.length - 1);
    }
    // console.log('daby',data.paginatedChartSeries.length)
  }

  setChartParams(chartTag, chartTypeTag, scaleTag, maxSeriesPerPage, uniqueID) {
    this.ChartTag = chartTag;
    this.ChartTypeTag = chartTypeTag;
    this.ScaleTag = scaleTag;
    this.maxSeriesPerPage = maxSeriesPerPage || DEFAULT_MAX_SERIES_PER_PAGE;
    this.uniqueID = uniqueID;
  }

  paginatedCategories(chartOptions, seriesPerPage) {
    const categories = chartOptions.xaxis.categories.reverse();
    const paginatedCategories = [];
    const totalPages = Math.ceil(categories.length / seriesPerPage);

    for (let pageIndex = 0; pageIndex < totalPages; pageIndex++) {
      const start = pageIndex * seriesPerPage;
      const end = start + seriesPerPage;
      paginatedCategories.push(categories.slice(start, end));
    }

    return paginatedCategories;
  }

  paginateSeries(chartSeries, seriesPerPage) {
    const paginatedSeries = [];
    const totalPages = Math.ceil(chartSeries[0].data.length / seriesPerPage);

    for (let pageIndex = 0; pageIndex < totalPages; pageIndex++) {
      const page = chartSeries.map((chartSerie) => ({
        ...chartSerie,
        data: chartSerie.data.slice(
          pageIndex * seriesPerPage,
          (pageIndex + 1) * seriesPerPage
        ),
      }));
      paginatedSeries.push(page);
    }

    return paginatedSeries;
  }

  convertData(data) {
    const showPopup = (data, seriesIndex, currentIndex, seriesData = null) => {
      const actualIndex =
        this.currentPage * this.maxSeriesPerPage + currentIndex;

      const popupData = (
        !!seriesData
          ? seriesData
          : this.data.chartSeries[seriesIndex].originalData
      )[actualIndex].Popup;

      if (popupData) {
        this.popupData = PopUpBuilder({
          label: popupData.Label,
          description: popupData?.ShortDescription || "",
          attributes: popupData.Attributes,
        });
      }
    };
    const maxSeriesPerPage =
      this.maxSeriesPerPage || DEFAULT_MAX_SERIES_PER_PAGE;
    const chartData =
      data.Chart.DataSets &&
      analyzeData(data, showPopup, this.uniqueID, maxSeriesPerPage);
    const chartSeries = chartData.chartSeries.map((serie) => ({
      ...serie,
      data: serie.data,
    }));
    const paginatedChartSeries = this.paginateSeries(
      chartSeries,
      this.maxSeriesPerPage
    );
    const paginatedCategories = this.paginatedCategories(
      chartData.chartOptions,
      this.maxSeriesPerPage
    );
    return {
      ChartMetrics: {
        chartSeries: chartSeries,
        chartOptions: chartData.chartOptions,
        paginatedCategories: paginatedCategories,
        showPopup: showPopup,
        paginatedChartSeries: paginatedChartSeries,
        shouldPaginate:
          chartData.chartSeries[0].data.length > this.maxSeriesPerPage,
      },
    };
  }

  fillData(callback, savedFilters = {}) {
    this.setLoading(true);
    this.getData(savedFilters)
      .then((response) => {
        this.setData(response);
        callback && callback(response);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {});
  }

  setAppliedFilters(
    filters = {},
    fillData = true,
    callback = null,
    overrideData = false
  ) {
    const transformedFilters = this.transformFilters(filters);
    this.filter = transformedFilters
    this.offset = 0;
    this.data = {};
    if (fillData) {
      this.fillData(callback, filters);
      this.setCurrentPage(0);
      this.setLoading(false);
    }
  }

  transformFilters(filters) {
    const storeFilters = [];
    Object.keys(filters)?.map((key) => {
      const singleSelectedFilter = filters[key];

      if (Array.isArray(singleSelectedFilter.value)) {
        storeFilters.push({
          ColumnName: key,
          Value: singleSelectedFilter.value.join("|"),
          Operand: "EQ",
        });
      } else {
        const isDate = singleSelectedFilter.filterType === "date";
        const isAmount = singleSelectedFilter.filterType === "amount";
        if (isDate || isAmount) {
          const hasRange = singleSelectedFilter.value.split("|").length > 1;
          storeFilters.push({
            ColumnName: key,
            Value: `${
              hasRange
                ? singleSelectedFilter.value.split("|")[0]
                : singleSelectedFilter.value
            }${isDate ? "T00:00:00.000" : ""}`,
            Operand: "GTE",
          });
          storeFilters.push({
            ColumnName: key,
            Value: `${
              hasRange
                ? singleSelectedFilter.value.split("|")[1]
                : singleSelectedFilter.value
            }${isDate ? "T23:59:59.999" : ""}`,
            Operand: "LTE",
          });
        } else if (singleSelectedFilter.filterType === "checkbox") {
          const valueToSend = singleSelectedFilter.value.split(",").join("|");
          storeFilters.push({
            ColumnName: key,
            Value: valueToSend,
            Operand: singleSelectedFilter.operator,
          });
        } else if (singleSelectedFilter.filterType === "boolean") {
          const valueToSend = singleSelectedFilter.value.toString();
          storeFilters.push({
            ColumnName: key,
            Value: valueToSend,
            Operand: singleSelectedFilter.operator,
          });
        } else if (singleSelectedFilter.filterType === "integer") {
          const valueToSend = parseInt(singleSelectedFilter.value);
          storeFilters.push({
            ColumnName: key,
            Value: valueToSend,
            Operand: singleSelectedFilter.operator,
          });
        } else {
          storeFilters.push({
            ColumnName: key,
            Value: singleSelectedFilter.value,
            Operand: singleSelectedFilter.operator,
          });
        }
      }
      return singleSelectedFilter;
    });
    return storeFilters.length > 0 ? [storeFilters] : null;
  }

  async getData(savedFilters) {
    const externalurl = `/api/v1/Dashboards/GetChartMetrics`;
    const activityTag = "GetChartMetrics";

    const apiRequest = apiHelper({
      externalurl,
      activityTag,
      filter: {
        enabled: true,
        value:
          Object.keys(savedFilters).length > 0
            ? this.transformFilters(savedFilters)
            : this.filter,
      },
      sort: {
        enabled: true,
        value: this.sort,
      },
      extraBody: {
        ChartTag: this.ChartTag,
        ChartTypeTag: this.ChartTypeTag,
        ScaleTag: this.ScaleTag,
      },
      limit: this.limit,
      offset: this.offset,
    });

    try {
      const response = await apiRequest;
      return this.convertData(response).ChartMetrics;
    } catch (error) {
      this.error = error;
      throw error;
    }
  }
}

export default Store;
