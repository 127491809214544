import React, { useState, forwardRef } from "react";

const CheckboxGroup = forwardRef(({
  group,
  handleChange,
  value,
  handleSelectAll,
  handleDeselectAll,
  index,
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const filterCheckboxes = (checkboxes) => {
    return checkboxes.filter((checkbox) => {
      const matchesParent = checkbox.displayName
        .toLowerCase()
        .includes(searchTerm);
      const matchesChildren = checkbox.children?.some((child) =>
        child.displayName.toLowerCase().includes(searchTerm)
      );
      return matchesParent || matchesChildren;
    });
  };

  const filteredValues = filterCheckboxes(group.values);

  const handleSelectAllInner = (e, parent) => {
      return handleSelectAll(parent.children, group);
  };
  const handleDeselectAllInner = (e, parent) => {
      return handleDeselectAll(parent.children, group);
  };

  return (
    <>
      <label className="font-bold rtl:text-right">{group.displayName}</label>
      {group.searchable && (
        <div className="relative mb-3">
          <span className="absolute pt-[10px] left-0 pl-3 flex items-center pointer-events-none">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-5 h-5 text-gray-400"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 21l-5.197-5.197M15.803 15.803A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607z"
              />
            </svg>
          </span>
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="p-2 pl-10 border border-gray-300 rounded w-full"
            tabIndex={index}
          />
        </div>
      )}
      {group.name && (
        <span className="text-xs border-b-2 border-gray-100 py-1 rtl:text-right">
          {group.name}
        </span>
      )}
      <div className="flex flex-col gap-3 pl-1">
        {filteredValues?.length > 0 ? (
          filteredValues.map((parent) => {
            const allChildValues = parent?.children?.map(
              (child) => child?.value
            );
            const checkedChildValues = value[group.key]?.value || '';
            const splitValues = checkedChildValues !== '' && checkedChildValues.split(',');
            const areAllSiblingsChecked =
              allChildValues?.every((childValue) =>
                splitValues && splitValues?.includes(childValue)
              ) || Object.values(checkedChildValues).includes(parent.value);

            return (
              <div
                key={`${parent.value}_${areAllSiblingsChecked}`}
                className="flex flex-col gap-1 "
              >
                {parent.selectable ? (
                  <div className="flex flex-col">
                    <label className="inline-flex items-center">
                      <input
                      key={group.key}
                        type="checkbox"
                        value={parent.value}
                        className="form-checkbox text-primary-500 h-4 w-4 cursor-pointer"
                        {...(areAllSiblingsChecked && {
                          checked: true,
                        })}
                        onChange={(e) => handleChange(e, parent, false, group)}
                        tabIndex={index}
                      />
                      <span className="rtl:mr-2 ltr:ml-2">{parent.displayName}</span>
                    </label>
                    <div className="text-sm ml-6">{parent.description}</div>
                  </div>
                ) : (
                  <div className="flex flex-col">
                    <div className="flex items-center justify-between">
                      {parent.displayName !== "All" && <span>{parent.displayName}</span>}
                      <div className="flex gap-2">
                        <button
                          className="text-xs text-primary-500 hover:underline"
                          onClick={(e) => handleSelectAllInner(e, parent)}
                          tabIndex={index}
                        >
                          Select All
                        </button>
                        <button
                          className="text-xs text-primary-500 hover:underline"
                          onClick={(e) => handleDeselectAllInner(e, parent)}
                          tabIndex={index}
                        >
                          Deselect All
                        </button>
                      </div>
                    </div>
                    <div className="text-sm pl-1">{parent.description}</div>
                  </div>
                )}
                {parent.children && (
                  <div className="pt-1 max-h-32 overflow-y-auto">
                    {parent.children.map((child, index) => {
                      const isChildVisible = child.displayName
                        .toLowerCase()
                        .includes(searchTerm);
                      if (isChildVisible) {
                        const valuesArray = value[group.key]?.value?.split(',');
                        const checked = valuesArray?.includes(child.value);
                        return (
                          <div key={index} className="flex flex-col">
                            <div
                              key={`${child.value}_${checked}`}
                              className="ltr:ml-4 rtl:mr-4 rtl:text-right"
                            >
                              <label className="inline-flex items-center">
                                <input
                                  value={child.value}
                                  type="checkbox"
                                  className="form-checkbox text-primary-500 h-4 w-4 cursor-pointer"
                                  {...(checked && {
                                    checked: true,
                                  })}
                                  onChange={(e) =>
                                    handleChange(e, child, true, group)
                                  }
                                  tabIndex={index}
                                />
                                <span className="ltr:ml-2 rtl:mr-2">
                                  {child.displayName}
                                </span>
                              </label>
                            </div>
                            <div className="text-sm ml-11">
                              {child.description}
                            </div>
                          </div>
                        );
                      }
                      return null;
                    })}
                  </div>
                )}
              </div>
            );
          })
        ) : (
          <>no results found for this keyword</>
        )}
      </div>
    </>
  );
});

CheckboxGroup.displayName = "CheckboxGroup";

export default CheckboxGroup;
