import { observable, action, makeObservable } from "mobx";
// import { MESSAGE_TYPE } from "@utils/Constants";
import { MESSAGE_TYPE } from "../utils/constants";
// import SessionStore from "@stores/SessionStore";

class SfmModalStore {
  showing = false;
  title = "";
  message = "";
  type = "error";
  afterClose = null;
  IsSessionExpired = null;
  queuedMessages = [];

  constructor() {
    makeObservable(this, {
      showing: observable,
      title: observable,
      message: observable,
      type: observable,
      afterClose: observable,
      IsSessionExpired: observable,
      queuedMessages: observable,
      open: observable,
      close: action,
    });
  }

  setShowing(showing) {
    this.showing = showing;
  }

  open(sfmObject, afterClose = null) {
    if (!this.showing) {
      if (sfmObject.ShowMessage) {
        this.setShowing(sfmObject.ShowMessage);
        this.title = sfmObject.Message;
        this.message = sfmObject.DetailedMessage;
        this.type = MESSAGE_TYPE[sfmObject.Type];
        this.IsSessionExpired = sfmObject.IsSessionExpired;
        this.afterClose = afterClose;
      } else {
        this.setShowing(false);
        this.title = "";
        this.message = "";
        this.type = MESSAGE_TYPE[1];
        this.IsSessionExpired = false;
      }
    } else {
      this.queuedMessages.push({
        showing: sfmObject.ShowMessage,
        title: sfmObject.Message,
        message: sfmObject.DetailedMessage,
        type: MESSAGE_TYPE[sfmObject.Type],
        IsSessionExpired: sfmObject.IsSessionExpired,
        afterClose: afterClose,
      });
    }
  }

  close() {
    this.showing = false;
    if (this.IsSessionExpired) {
      console.warn("Expired");
    } else {
      this.afterClose && this.afterClose();

      if (this.queuedMessages.length) {
        let nextMessage = this.queuedMessages.pop();

        this.open(nextMessage);
      }
    }
  }
}

// decorate(SfmModalStore, {
//   showing: observable,
//   title: observable,
//   message: observable,
//   type: observable,
//   afterClose: observable,
//   IsSessionExpired: observable,
//   queuedMessages: observable,
//   open: action,
//   close: action,
// });

const store = new SfmModalStore();
export default store;
