import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { resolveStatusColumn } from "@helpers/helpers";

function LegendComponent({ data, inline = false }) {
  const { t: translate, i18n } = useTranslation();
  const [opened, setOpened] = useState(false);

  const defaultData = [
    {
      text: translate("shared.legend.inProgress"),
      color: "#cbd5e0",
      pulse: true,
      tag: "INPROGRESS",
    },
    {
      text: translate("shared.legend.active"),
      color: "#68d391",
      tag: "ACTIVE",
    },
    {
      text: translate("shared.legend.completed"),
      color: "#68d391",
      tag: "COMPLETED",
    },
    {
      text: translate("shared.legend.disabled"),
      color: "#fc8181",
      tag: "DISABLED",
    },
    {
      text: translate("shared.legend.failed"),
      color: "#fc8181",
      tag: "FAILED",
    },
  ];

  const dataToShow = [...defaultData, ...data];

  useEffect(() => {
    let TOP_OFFSET = 30;
    let LEFT_OFFSET = 0;
    if (opened) {
      setTimeout(() => {
        const parent = document.getElementById(`parent_legend`);
        const child = document.getElementById(`child`);

        if (child) {
          const parentRect = parent.getBoundingClientRect();

          const top = parentRect.top;
          const left = parentRect.left;

          if (i18n.language === "ar") {
            LEFT_OFFSET = -1 * child.offsetWidth;
            TOP_OFFSET = 15;
          }

          child.style.top = `${top + TOP_OFFSET}px`;
          child.style.left = `${left + LEFT_OFFSET}px`;
        }
      }, 0);
    }
  }, [opened]);

  const handleClick = () => {
    setOpened(!opened);
  };

  const renderContent = () => {
    return dataToShow.map((item, index) => (
      <div
        key={index}
        className={clsx(
          !inline && "rtl:flex-row-reverse",
          `flex w-max gap-3 items-center`
        )}
      >
        {/* <span
          style={{ background: item.color }}
          className={`inline-flex h-3 w-3 rounded-full opacity-75 border-[1px] border-gray-200`}
        ></span> */}
        {resolveStatusColumn("", item.tag, ["INPROGRESS"], null, "")}
        <span className="text-sm">{item.text}</span>
      </div>
    ));
  };

  if (inline) {
    return <div className="flex gap-3 items-center">{renderContent()}</div>;
  }

  return (
    <div>
      <svg
        id="parent_legend"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="size-6 hover:scale-110 hover:text-primary-800 transition-all duration-200 cursor-pointer"
        // onClick={() => handleClick()}
        onMouseEnter={() => setOpened(true)}
        onMouseLeave={() => setOpened(false)}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
        />
      </svg>
      {opened && (
        <div
          id="child"
          className="flex flex-col ltr:left-[3px] rtl:items-end gap-3 p-4 fixed bg-white shadow-xl rounded-md text-xs z-[1]"
        >
          {renderContent()}
        </div>
      )}
    </div>
  );
}

export default LegendComponent;
