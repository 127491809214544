import React from "react";
import { Tooltip } from "react-tooltip";

const TooltipComponent = ({
  anchorElement,
  toolTipMessage,
  position = "top",
  className,
}) => {
  return (
    <Tooltip id={anchorElement} place={position} className={className}>
      {toolTipMessage}
    </Tooltip>
  );
};

export default TooltipComponent;
