import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Highlighter from "react-highlight-words";

const transformData = (data, i18n) => {
  const regions = data.filter((item) => item.Level === 0);
  const outlets = data.filter((item) => item.Level === 1);
  const branchesAndTerminals = data.filter((item) => item.Level === 2);

  return regions.map((region) => ({
    id: region.OrgId,
    name: region.OrgDetails.find(
      (detail) => detail.LanguageCode === i18n.language
    ).ShortName,
    outlets: outlets
      .filter((outlet) => outlet.ParentOrgId === region.OrgId)
      .map((outlet) => {
        const branches = branchesAndTerminals.filter(
          (item) =>
            item.ParentOrgId === outlet.OrgId &&
            item.OrgDetails.some((detail) =>
              detail.ShortName.includes("Branch")
            )
        );
        const terminals = branchesAndTerminals.filter(
          (terminal) => terminal.ParentOrgId === outlet.OrgId
        );

        const branchData = branches.map((branch) => {
          const addresses = branch.OrgAddresses.map((address) => {
            const addressDetail = address.AddressDetails.find(
              (detail) => detail.LanguageCode === i18n.language
            );
            return `${addressDetail.District}, ${addressDetail.Sector}, ${addressDetail.City}, ${addressDetail.StreetAddress}`;
          });

          const contacts = branch.OrgContacts.map((contact) => ({
            ContactValue: contact.ContactValue,
            ContactTypeTag: contact.ContactTypeTag,
          }));

          const devices = terminals.flatMap((terminal) =>
            terminal.OrgDevices.map((device) => ({
              id: device.DeviceId,
              name: device.DeviceName,
            }))
          );

          const attributes = branch.OrgAttributes.map(
            (attribute) => attribute.AttributeValue
          ).join(" | ");

          return {
            id: branch.OrgId,
            name: `${
              branch.OrgDetails.find(
                (detail) => detail.LanguageCode === i18n.language
              ).ShortName
            } (${attributes})`,
            addresses,
            contacts,
            terminals: devices,
          };
        });

        return {
          id: outlet.OrgId,
          name: outlet.OrgDetails.find(
            (detail) => detail.LanguageCode === i18n.language
          ).ShortName,
          branches: branchData,
        };
      }),
  }));
};

const HierarchyComponent = ({
  data,
  setInfoPopupOpen,
  setCurrentBranch,
  searchTerm,
  setSearchTerm,
}) => {
  const { t: translate, i18n } = useTranslation();
  const hierarchy = transformData(data, i18n);

  const [expandedRegions, setExpandedRegions] = useState(
    !!hierarchy[0]?.id
      ? {
          [hierarchy[0]?.id]: true,
        }
      : {}
  );

  const [showAllTerminals, setShowAllTerminals] = useState({});
  const [expandedOutlets, setExpandedOutlets] = useState({});
  const [expandedBranches, setExpandedBranches] = useState({});
  const [currentPage, setCurrentPage] = useState(1);

  const outletsperPage = 10;
  const terminalsToShow = 15;

  const toggleRegion = (regionId) => {
    setExpandedRegions((prev) => ({
      ...prev,
      [regionId]: !prev[regionId],
    }));
  };

  const toggleOutlet = (outletId) => {
    setExpandedOutlets((prev) => ({
      ...prev,
      [outletId]: !prev[outletId],
    }));
  };

  const toggleBranch = (branchId) => {
    setExpandedBranches((prev) => ({
      ...prev,
      [branchId]: !prev[branchId],
    }));
  };

  useEffect(() => {
    setCurrentPage(1);

    if (searchTerm === "") {
      setExpandedOutlets({});
      setExpandedBranches({});
    }
  }, [searchTerm]);

  const filteredHierarchy = useMemo(() => {
    const expandedRegions = {};
    const expandedOutlets = {};
    const expandedBranches = {};

    if (searchTerm === "") {
      return hierarchy;
    }

    const filterHierarchy = (hierarchy, searchTerm) => {
      return hierarchy
        .map((region) => {
          const filteredOutlets = region.outlets
            .map((outlet) => {
              const filteredBranches = outlet.branches
                .map((branch) => {
                  const filteredAddresses = branch.addresses.filter((address) =>
                    address.toLowerCase().includes(searchTerm.toLowerCase())
                  );
                  const filteredContacts = branch.contacts.filter((contact) =>
                    contact.ContactValue.toLowerCase().includes(searchTerm.toLowerCase())
                  );
                  const filteredTerminals = branch.terminals.filter(
                    (terminal) =>
                      terminal.name
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                  );

                  if (
                    branch.name
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase()) ||
                    filteredAddresses.length > 0 ||
                    filteredContacts.length > 0 ||
                    filteredTerminals.length > 0
                  ) {
                    expandedBranches[branch.id] = true;
                    return {
                      ...branch,
                    };
                  }
                  return null;
                })
                .filter((branch) => branch !== null);

              if (
                outlet.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                filteredBranches.length > 0
              ) {
                expandedOutlets[outlet.id] = true;
                return {
                  ...outlet,
                };
              }
              return null;
            })
            .filter((outlet) => outlet !== null);

          if (
            region.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            filteredOutlets.length > 0
          ) {
            expandedRegions[region.id] = true;
            return {
              ...region,
              outlets: filteredOutlets,
            };
          }
          return null;
        })
        .filter((region) => region !== null);
    };

    const result = filterHierarchy(hierarchy, searchTerm);
    setExpandedRegions(expandedRegions);
    setExpandedOutlets(expandedOutlets);
    setExpandedBranches(expandedBranches);
    return result;
  }, [searchTerm]);

  const totalPages = Math.ceil(
    filteredHierarchy[0]?.outlets.length / outletsperPage
  );
  const currentOutlets = filteredHierarchy[0]?.outlets.slice(
    (currentPage - 1) * outletsperPage,
    currentPage * outletsperPage
  );

  const handleNextPage = () => {
    setExpandedOutlets({});
    setExpandedBranches({});
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const handlePreviousPage = () => {
    setExpandedOutlets({});
    setExpandedBranches({});
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleToggleShowAll = (branchId) => {
    setShowAllTerminals((prev) => ({
      ...prev,
      [branchId]: !prev[branchId],
    }));
  };

  return (
    <div>
      <input
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onChange={(e) => {
          setSearchTerm(e.target.value);
        }}
        className="mb-4 p-2 border rounded"
      />
      {filteredHierarchy.length > 0 ? (
        filteredHierarchy.map((region) => (
          <div key={region.id} className="mb-4 py-4 p-2 rounded">
            <div
              onClick={() => toggleRegion(region.id)}
              className="text-xl font-bold cursor-pointer flex items-center"
            >
              <span
                className={`transform transition-transform duration-500 ${
                  expandedRegions[region.id] ? "rotate-90" : "rotate-0"
                }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m8.25 4.5 7.5 7.5-7.5 7.5"
                  />
                </svg>
              </span>
              <div className="flex gap-2 ml-2 items-baseline">
                <span className=" text-gray-900">{region.name}</span>
                <span className=" text-xs text-gray-400">
                  {region.outlets.length} region
                  {region.outlets.length > 1 ? "s" : ""}
                </span>
                <span className="text-primary-500 text-xs">
                  {searchTerm !== "" && ` matching "${searchTerm}"`}
                </span>
              </div>
            </div>
            <div
              className={`ml-[11px] transition-all border-l-2 border-gray-200 duration-500 overflow-hidden ${
                expandedRegions[region.id]
                  ? "max-h-screen mt-2"
                  : "max-h-0 mt-0"
              }`}
            >
              {currentOutlets.map((outlet) => {
                return (
                  <div
                    key={outlet.id}
                    className="my-1 flex flex-col p-2 pl-6 py-4 last-of-type:pb-0 last-of-type:mb-0 rounded"
                  >
                    <div
                      onClick={() => toggleOutlet(outlet.id)}
                      className="text-lg font-semibold cursor-pointer flex items-center"
                    >
                      <span
                        className={`transform transition-transform duration-300 ${
                          expandedOutlets[outlet.id] ? "rotate-90" : "rotate-0"
                        }`}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m8.25 4.5 7.5 7.5-7.5 7.5"
                          />
                        </svg>
                      </span>
                      <div className="flex ml-2 gap-2 items-baseline">
                        <span className=" text-gray-800">
                          {" "}
                          <Highlighter
                            highlightClassName="text-red-500 bg-transparent"
                            searchWords={[searchTerm]}
                            autoEscape={true}
                            textToHighlight={outlet.name}
                          />
                        </span>
                        <span className=" text-xs text-gray-400">
                          {outlet.branches.length} branch
                          {outlet.branches.length > 1 ? "es" : ""}
                        </span>
                      </div>
                    </div>
                    <div
                      className={`ml-[11px] transition-all border-l-2 border-gray-200 duration-500 overflow-hidden ${
                        expandedOutlets[outlet.id]
                          ? "max-h-screen mt-2"
                          : "max-h-0 mt-0"
                      }`}
                    >
                      {outlet.branches.map((branch) => (
                        <>
                          <div key={branch.id} className="pl-6 mt-4">
                            <div className="flex justify-between items-center">
                              <div
                                onClick={() =>
                                  branch.terminals.length > 0 &&
                                  toggleBranch(branch.id)
                                }
                                className="text-md font-semibold cursor-pointer flex items-center"
                              >
                                <span
                                  className={`transform transition-transform duration-300 ${
                                    expandedBranches[branch.id]
                                      ? "rotate-90"
                                      : "rotate-0"
                                  }`}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="size-6"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="m8.25 4.5 7.5 7.5-7.5 7.5"
                                    />
                                  </svg>
                                </span>
                                <div className="flex items-baseline">
                                  <span className="ml-2 text-gray-800">
                                    <Highlighter
                                      highlightClassName="text-red-500 bg-transparent"
                                      searchWords={[searchTerm]}
                                      autoEscape={true}
                                      textToHighlight={branch.name}
                                    />
                                  </span>
                                  <span className="ml-2 text-xs text-gray-400">
                                    {branch.terminals.length} terminal
                                    {branch.terminals.length > 1 ? "s" : ""}
                                  </span>
                                </div>
                              </div>
                              {branch.addresses.length > 0 && (
                                <div
                                  className="cursor-pointer bg-gray-200 p-1 rounded hover:bg-gray-300"
                                  onClick={() => {
                                    setCurrentBranch(branch);
                                    setInfoPopupOpen(branch.id);
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="size-5"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                                    />
                                  </svg>
                                </div>
                              )}
                            </div>
                            <div
                              className={`ml-[11px] transition-all flex items-baseline border-l-2 border-gray-200 duration-500 overflow-hidden ${
                                expandedBranches[branch.id]
                                  ? "max-h-screen mt-2"
                                  : "max-h-0 mt-0"
                              }`}
                            >
                              {branch.terminals.length > 0 && (
                                <div className="flex flex-col items-start px-5 mt-7 gap-4">
                                  <p className="font-semibold text-gray-700">
                                    Terminals:{" "}
                                  </p>
                                  <div className="grid grid-cols-5 gap-4 items-center px-5 pl-0 flex-1">
                                    {branch.terminals
                                      .slice(
                                        0,
                                        showAllTerminals[branch.id]
                                          ? branch.terminals.length
                                          : terminalsToShow
                                      )
                                      .map((terminal, index) => (
                                        <div
                                          key={terminal.id}
                                          className="flex items-center justify-start pb-1"
                                        >
                                          <h4 className="font-medium">
                                            <Highlighter
                                              highlightClassName="text-red-500 bg-transparent"
                                              searchWords={[searchTerm]}
                                              autoEscape={true}
                                              textToHighlight={terminal.name}
                                            />
                                          </h4>
                                        </div>
                                      ))}
                                  </div>
                                  {branch.terminals.length >
                                    terminalsToShow && (
                                    <button
                                      className="pb-4 text-gray-500 text-sm underline"
                                      onClick={() =>
                                        handleToggleShowAll(branch.id)
                                      }
                                    >
                                      {showAllTerminals[branch.id]
                                        ? "Show Less"
                                        : "View All"}
                                    </button>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ))
      ) : (
        <div className="text-center w-full flex flex-col items-center gap-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21 12a2.25 2.25 0 0 0-2.25-2.25H15a3 3 0 1 1-6 0H5.25A2.25 2.25 0 0 0 3 12m18 0v6a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 18v-6m18 0V9M3 12V9m18 0a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 9m18 0V6a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 6v3"
            />
          </svg>
          <span>No Result matches your search criteria: {searchTerm}</span>
        </div>
      )}
      {filteredHierarchy[0]?.outlets.length > outletsperPage && (
        <div className="flex justify-center mt-4">
          <button
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
            className="px-4 py-2 mx-2 border rounded disabled:opacity-50"
          >
            Previous
          </button>
          <span className="px-4 py-2 mx-2">{`Page ${currentPage} of ${totalPages}`}</span>
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            className="px-4 py-2 mx-2 border rounded disabled:opacity-50"
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default HierarchyComponent;
