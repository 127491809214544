import React from "react";
import CustomTable from "@components/CustomTable/table";

import { TableProvider } from "@components/CustomTable/context";
import PropTypes from "prop-types";

const CustomTableWrapper = (props) => {
  return (
    <TableProvider tableProps={props}>
      <CustomTable {...props} />
    </TableProvider>
  );
};

CustomTableWrapper.propTypes = {
  minimalTable: PropTypes.bool,
  language: PropTypes.oneOf(['en', 'ar']),
};

export default CustomTableWrapper;