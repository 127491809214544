import React, { useEffect, useState } from "react";
import { Observer } from "mobx-react";
import DownloadModalStore from "@stores/DownloadModalStore";
import CustomTable from "@components/CustomTable";
import ButtonComponent from "@components/ButtonComponent";
import Confirmationpopup from "@components/ConfirmationPopup";
import Loader from "@components/LoaderComponent/loader";
import clsx from "clsx";
import { toast } from "react-toastify";

import {
  noDataHelper,
  removeFromDownloadCenter,
  clearDownloadCenter,
  humanize,
  ExportReport,
  ExportReconciliationRecords,
  GetSFMDisplay,
  updateDownloadItem,
  addToDownloadCenter,
  extractFileName,
  extractFileSize,
} from "@helpers/helpers";
import { withTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const DownloadModal = ({ t: translate, i18n }) => {
  const location = useLocation();
  const DOWNLOAD_CENTER_KEY = "DOWNLOAD_CENTER";

  useEffect(() => {
    DownloadModalStore.setShowing(false);
  }, [location]);

  useEffect(() => {
    if (!reFetchingDownloads) {
      setReFetchingDownloads(true);
      let downloads = (
        localStorage.getItem(DOWNLOAD_CENTER_KEY)
          ? JSON.parse(localStorage.getItem(DOWNLOAD_CENTER_KEY))
          : []
      ).filter((item) => item.done === false && item.apiEndpoint);

      downloads.forEach((item) => {
        console.log(item);
        if (item.apiEndpoint === "ExportReport") {
          const toastId = toast.loading(
            `${translate("reports.processing-pending-download")} ${humanize(item.activityTag)}...`,
          );
          updateDownloadItem({
            id: item.id,
            newProps: {
              toastId: toastId,
            },
          });
          ExportReport(
            item.id,
            item.apiParams,
            (data) => {
              if (data.File) {
                const fileName = extractFileName(data.File);
                const fileSize = extractFileSize(data.File);
                updateDownloadItem({
                  id: item.id,
                  newProps: {
                    href: data.File,
                    title: fileName,
                    fileSize: fileSize,
                    done: true,
                  },
                });
                toast.update(toastId, {
                  render: translate("reports.download-ready"),
                  type: "success",
                  isLoading: false,
                  autoClose: 3000,
                });
                DownloadModalStore.open(500);
                DownloadModalStore.highlight(500, item.id);
                window.open(data.File, "_blank");
              } 
              // else {
              //   const sfmData = GetSFMDisplay(data.SFM);
              //   toast.update(toastId, {
              //     render: `${sfmData.Message}`,
              //     type: "warning",
              //     isLoading: false,
              //     autoClose: 5000,
              //   });
              //   // updateDownloadItem({
              //   //   id: item.id,
              //   //   newProps: {
              //   //     fileRetrievalID: data.FileRetrievalId,
              //   //   },
              //   // });
              // }
            },
            toastId,
            translate("download-center.actions.download-ready"),
            item.reportType,
            item.fileRetrievalId
          );
        } else if (item.apiEndpoint === "ExportReconciliationRecords") {
          const toastId = toast.loading(
            `${translate("reports.processing-pending-download")} ${humanize(item.activityTag)}...`,
          );
          updateDownloadItem({
            id: item.id,
            newProps: {
              toastId: toastId,
            },
          });
          ExportReconciliationRecords(
            item.id,
            item.apiParams,
            null,
            item.activityTag,
            (data) => {
              if (data.File) {
                const fileName = extractFileName(data.File);
                const fileSize = extractFileSize(data.File);
                updateDownloadItem({
                  id: item.id,
                  newProps: {
                    href: data.File,
                    title: fileName,
                    fileSize: fileSize,
                    done: true,
                  },
                });
                toast.update(toastId, {
                  render: translate("reports.download-ready"),
                  type: "success",
                  isLoading: false,
                  autoClose: 3000,
                });
                DownloadModalStore.open(500);
                DownloadModalStore.highlight(500, item.id);
                window.open(data.File, "_blank");
              } 
              // else {
              //   const sfmData = GetSFMDisplay(data.SFM);
              //   toast.update(toastId, {
              //     render: `${sfmData.Message}`,
              //     type: "warning",
              //     isLoading: false,
              //     autoClose: 5000,
              //   });
              //   // updateDownloadItem({
              //   //   id: item.id,
              //   //   newProps: {
              //   //     fileRetrievalId: data.FileRetrievalId,
              //   //   },
              //   // });
              // }
            },
            null,
            toastId,
            translate("download-center.actions.download-ready"),
            item.fileRetrievalId
          );
        }
      });
    }
  }, []);

  const [reFetchingDownloads, setReFetchingDownloads] = useState(false);

  const [openConfirmationAction, setOpenConfirmationAction] = useState(false);

  const getContent = (type) => {
    let content = localStorage.getItem(DOWNLOAD_CENTER_KEY)
      ? JSON.parse(localStorage.getItem(DOWNLOAD_CENTER_KEY))
      : [];

    // sort content by creationDate then activity tag then creation time

    content.sort((a, b) => {
      const dateA = new Date(`${a.creationDate} ${a.creationTime}`);
      const dateB = new Date(`${b.creationDate} ${b.creationTime}`);
      return dateB - dateA;
    });

    const columns = [
      {
        header: "Activity",
        accessorKey: "activityTag",
        truncate: true,
        size: "100px",
        cell: ({ row }) => {
          return (
            <span className="text-xs">
              {humanize(row.original.activityTag)}
            </span>
          );
        },
      },
      {
        header: "Requested at",
        accessorKey: "creationDate",
        size: "200px",
        cell: ({ row }) => {
          return (
            <div className="flex flex-col gap-1 items-center">
              <span className="text-gray-500 text-xs whitespace-nowrap">
                {row.original.creationDate}
              </span>
              <span className="text-gray-500 text-xs whitespace-nowrap">
                {row.original.creationTime}
              </span>
            </div>
          );
        },
      },
      {
        header: "File Name",
        accessorKey: "title",
        size: "130px",
        // truncate: true,
        cell: ({ row }) => {
          return (
            <span className="text-xs">
              {row.original.title.replace(/_/g, " ")}
            </span>
          );
        },
      },
      {
        header: "File Size",
        accessorKey: "size",
        size: "120px",
        // truncate: true,
        cell: ({ row }) => {
          const sizeToShow = parseFloat(row.original.fileSize)
            .toFixed(2)
            .replace("0.00", " 0.01");
          return (
            <span className="text-xs whitespace-nowrap">
              {isNaN(sizeToShow) ? " " : sizeToShow + " MB"}
            </span>
          );
        },
      },
      // {
      //   header: "File ID",
      //   accessorKey: "title",
      //   size: "130px",
      //   // truncate: true,
      //   cell: ({ row }) => {
      //     return row.original.title.replace(/_/g, " ");
      //   },
      // },
      {
        header: "Status",
        accessorKey: "status",
        size: "50px",
        cell: ({ row }) => {
          return (
            <div className="flex gap-1 items-center flex-col">
              <span
                className={clsx(
                  "text-xs px-2 py-1 rounded-full",
                  row.original.done
                    ? "bg-green-500 text-white"
                    : "bg-red-500 text-white"
                )}
              >
                {row.original.done ? "Ready" : "Processing"}
              </span>
              {DownloadModalStore.highlighted === row.original.id && (
                <span
                  className={clsx(
                    "text-xs px-2 py-1 rounded-full bg-primary-500 text-white"
                  )}
                >
                  <span className="animate-pulse">{"New"}</span>
                </span>
              )}
            </div>
          );
        },
      },
      {
        header: "Type",
        accessorKey: "fileType",
        size: "50px",
      },

      // {
      //   header: "Size",
      //   accessorKey: "fileSize",
      //   size: "50px",
      // },
    ];
    const actions = {
      DOWNLOAD: {
        // actionTypeTag: "FILE_DROP",
        name: translate("download-center.actions.download"),
        hideLoading: true,
        actionFN: (row) => {
          if (row.original.href !== "#") {
            window.open(row.original.href, "_blank");
          }
          if (!row.original.done) {
            alert(
              "File is not ready yet, please wait for the processing to finish."
            );
          }
          // setOpenFileDropModal(true);
          // setCurrentDataAcquisitionId(row.original.DataAcquisitionId);
          // myStore.loading = false;
        },
        // confirmation: true,
      },
      DELETE: {
        // actionTypeTag: "FILE_DROP",
        name: translate("download-center.actions.delete"),
        hideLoading: true,
        actionFN: (row) => {
          removeFromDownloadCenter({ id: row.original.id });
          // setRefreshComponent(!refreshComponent);
          DownloadModalStore.refresh();
          // setOpenFileDropModal(true);
          // setCurrentDataAcquisitionId(row.original.DataAcquisitionId);
          // myStore.loading = false;
        },
        confirmation: true,
        confirmationMessage: translate(
          "download-center.actions.delete-confirmation"
        ),
      },
    };
    const pinningInitialState = ["pin", "status"];

    return (
      DownloadModalStore.showing && (
        <div className="flex flex-col gap-4">
          {/* <h1 className="text-black text-center">Download Center</h1> */}
          <div className="flex flex-col gap-4 w-[50dvw] text-black">
            <div className="flex justify-end items-center">
              <ButtonComponent
                className="text-sm min-w-max h-fit p-2 rounded-lg px-4 cursor-pointer bg-white"
                withoutBorder={true}
                id="clearDownloads"
                color="red"
                onClick={() => setOpenConfirmationAction(true)}
                ref={null}
                icon={null}
                text={translate("download-center.actions.clear-downloads")}
                disabled={content.length === 0}
              />
              {openConfirmationAction && (
                <Confirmationpopup
                  singleAction={{
                    actionFN: () => {
                      DownloadModalStore.setLoading(true);
                      clearDownloadCenter();
                      setTimeout(() => {
                        DownloadModalStore.setLoading(false);
                      }, 1000);
                      setTimeout(() => {
                        DownloadModalStore.setShowing(false);
                      }, 3000);
                    },
                    confirmation: true,
                    confirmationMessage: translate(
                      "download-center.actions.clear-downloads-confirmation"
                    ),
                    severity: "high",
                  }}
                  setOpenConfirmationAction={setOpenConfirmationAction}
                />
              )}
            </div>
            <CustomTable
              language={i18n.language}
              // store={{
              //   store: {
              //     loading: false,
              //   },
              // }}
              externalData={content.map((item) => {
                return {
                  ...item,
                  Actions: item.done ? ["DOWNLOAD", "DELETE"] : ["DELETE"],
                };
              })}
              columnsWithoutPin={columns}
              loading={false}
              pinCondition={(row) => {
                return false;
              }}
              pinningInitialState={pinningInitialState}
              setParentSelectedValues={() => {}}
              prefix="DownloadCenterTable"
              title={translate("download-center.title")}
              pinnedRowHeight={62}
              maxTableHeight={"calc(100dvh - 200px)"}
              showFilter={false}
              minimalTable={true}
              hideActionColumn={false}
              hidePinColumn={false}
              actions={actions}
              noData={() => noDataHelper(translate("shared.no-downloads"))}
              isExpandable={true}
            />
          </div>
        </div>
      )
    );
  };

  return (
    <Observer>
      {() => (
        <div
          className={clsx(
            `group`,
            DownloadModalStore.showing ? "visible" : "invisible"
          )}
        >
          <div
            className="fixed top-0 left-0 w-full h-full bg-[#000000] bg-opacity-20 z-[25]"
            onClick={() => DownloadModalStore.close()}
          />
          <div
            className={clsx(
              `fixed transition-all overflow-y-scroll h-dvh duration-500 ease-in-out z-50 bg-white text-black py-6 px-5 top-0`,
              DownloadModalStore.showing
                ? "ltr:right-0 rtl:left-0  min-w-[55%]"
                : "ltr:-right-36 rtl:-left-36  min-w-0"
            )}
          >
            <div className="flex flex-col gap-4">
              <div className="flex gap-3 justify-center items-center">
                <div className="w-full font-bold">
                  {" "}
                  {DownloadModalStore.title}
                </div>
                <button
                  onClick={() => DownloadModalStore.close()}
                  className="w-full justify-end flex"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="size-8 hover:text-red-800 transition-all duration-200"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18 18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div className="flex flex-col gap-2">
                {DownloadModalStore.loading ? (
                  <div className="w-full h-[calc(100dvh-200px)] flex items-center justify-center">
                    <Loader dots />
                  </div>
                ) : (
                  getContent()
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </Observer>
  );
};

export default withTranslation()(DownloadModal);
