import { observable, action, makeObservable } from "mobx";

class DownloadModalStore {
  showing = false;
  loading = false;
  highlighted = false;
  // title = "";
  // type = "";
  // data = null;
  afterClose = null;

  constructor() {
    makeObservable(this, {
      showing: observable,
      highlighted: observable,
      loading: observable,
      // title: observable,
      // type: observable,
      // data: observable,
      afterClose: observable,
      open: observable,
      highlight: observable,
      refresh: observable,
      close: action,
      setShowing: action,
      setLoading: action,
    });
  }

  setShowing(showing) {
    this.showing = showing;
  }

  setLoading(loading) {
    this.loading = loading;
  }

  open(timeout = 0) {
    // if (!this.showing) {
    // if (modalObject.show) {
    setTimeout(() => {
      // this.showing = true;
      this.setShowing(true);
    }, timeout);
    // this.title = modalObject.title;
    // this.type = modalObject.type;
    // this.data = modalObject.data
    // this.afterClose = afterClose;
    // }
    // }
  }

  highlight(timeout = 0, id) {
    // if (!this.showing) {
    // if (modalObject.show) {
    setTimeout(() => {
      this.highlighted = id;
    }, timeout);
    setTimeout(() => {
      this.highlighted = false;
    }, timeout + 20000);
    // this.title = modalObject.title;
    // this.type = modalObject.type;
    // this.data = modalObject.data
    // this.afterClose = afterClose;
    // }
    // }
  }

  close() {
    // this.showing = false;
    this.setShowing(false);
    // this.title = "";
    // this.data = null;
    this.afterClose && this.afterClose();
  }
  refresh() {
    // this.loading = true;
    this.setLoading(true);
    setTimeout(() => {
      // this.loading = false;
      this.setLoading(false);
    }, 1000);
    // this.title = "";
    // this.data = null;
    // this.afterClose && this.afterClose();
  }
}

const store = new DownloadModalStore();
export default store;
