import React, { useState } from "react";
import Chart from "react-apexcharts";
import PopupComponent from "@components/PopupComponent";

import { analyzeData } from "@helpers/chartsHelpers";
import ReactJson from "react-json-view";
import TitleComponent from "@components/TitleComponent";

// import response from "./response.json";
const ChartComponent = ({ title, custom = false }) => {
  const [popupData, setPopupData] = useState(null);
  const [response, setResponse] = useState(null);
  const [validChart, setValidChart] = useState(false);
  const [tempResponse, setTempResponse] = useState(null);

  const showPopup = (data) => {
    setPopupData(data || "No popup content available");
  };
  const [chartOptions2, setChartOptions2] = useState();
  // analyzeData(response, showPopup).chartOptions
  const [chartSeries2, setChartSeries2] = useState();
  // analyzeData(response, showPopup).chartSeries

  return (
    <div className="p-4 bg-white rounded shadow-md">
      <div className="flex justify-between mb-4">
        <div className="flex gap-4 items-center">
          {/* <h2 className="text-xl font-bold">{title}</h2> */}
          <TitleComponent text={title} size="md" />
          {/* <span>JSON goes here</span> */}
        </div>
        <span className="text-sm text-gray-500">
          {/* Last updated: 2024-02-14 12:00:00 */}
        </span>
      </div>
      {!response && (
        <textarea
          className={`w-full h-60 border-4 p-2 rounded ${
            validChart ? "border-green-300" : "border-red-300"
          }`}
          value={tempResponse}
          placeholder="Enter JSON response here"
          onChange={(e) => {
            setTempResponse(e.target.value);
            if (tempResponse !== response) {
              setValidChart(false);
            }
          }}
        />
      )}
      {!response && (
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded disabled:bg-gray-300 disabled:text-gray-500 mb-5"
          onClick={() => {
            setValidChart(false);
            setResponse(null);
            try {
              if (JSON.parse(tempResponse)) {
                const parsedResponse = JSON.parse(tempResponse);
                setResponse(parsedResponse);
                setChartOptions2(
                  analyzeData(parsedResponse, showPopup, 'custom-chart', 100).chartOptions
                );
                setChartSeries2(
                  analyzeData(parsedResponse, showPopup, 'custom-chart', 100).chartSeries
                );
                setValidChart(true);
              }
            } catch (e) {
              setValidChart(false);
              alert("Please enter a valid JSON response");
            }
          }}
          disabled={validChart}
        >
          Generate Chart
        </button>
      )}
      {response && (
        <ReactJson
          src={response}
          collapsed={true}
          enableClipboard={false}
          displayDataTypes={false}
          displayObjectSize={true}
          quotesOnKeys={false}
          displayArrayKey={false}
          theme="apathy:inverted"
          name={"Your JSON Response"}
        />
      )}

      {response && (
        <button
          className="bg-red-500 text-white px-4 py-2 rounded disabled:bg-gray-300 disabled:text-gray-500 mt-5"
          onClick={() => {
            setValidChart(false);
            setResponse(null);
          }}
        >
          Edit JSON
        </button>
      )}

      {/* <Chart
        options={chartOptions}
        series={chartSeries}
        type="line"
        height={350}
      /> */}
      {validChart && (
        <>
          <Chart
            options={chartOptions2}
            series={chartSeries2}
            type="line"
            height={350}
            // uniqueId="custom-chart"
          />
          <input
            type="hidden"
            id={`current_page_custom-chart`}
            value={0}
          />
        </>
      )}
      {popupData && (
        <PopupComponent
          open={popupData !== null}
          innerHtml={popupData}
          setPopupOpen={() => {
            setPopupData(null);
          }}
        />
      )}
      {/* <img style={{width: '100%'}} src={chartImg}/> */}
    </div>
  );
};

export default ChartComponent;
