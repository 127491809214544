import React, { useEffect, useState } from "react";
import { CopyToCliboard } from "@components/CustomTable/helpers";
import CustomTable from "@components/CustomTable";
import Loader from "@components/LoaderComponent/loader";
import myStore from "./store";
import {
  fetchDynamicFilters,
  transformData,
  FormatWithCommas,
} from "@helpers/helpers";
import FiltersWrapper from "@components/FiltersWrapperComponent";
import { Observer } from "mobx-react";
import TitleComponent from "@components/TitleComponent";
import { getLocalConfig } from "@components/CustomTable/helpers";
import PopupComponent from "@components/PopupComponent";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import clsx from "clsx";

const OutstandingRecords = ({
  exceptionDetailsMatchingRecord,
  setSelectedOutstandingDestinationRows,
  selectedOutstandingDestinationRows,
  setExternalOutstandingRecords,
  recordId,
}) => {
  const { t: translate, i18n } = useTranslation();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  const [outstandingRecords, setOutStandingRecords] = useState([]);
  const [dynamicFilters, setDynamicFilters] = useState([]);
  const [popupOpen, setPopupOpen] = useState(null);

  const getDynamicFilters = (key, onlyChildren = false) => {
    return transformData(
      (!!dynamicFilters &&
        dynamicFilters.length > 0 &&
        dynamicFilters.find((filter) => filter.ColumnName === key)?.Values) ||
        [],
      onlyChildren
    );
  };

  const textColors = {
    CRITICAL: "text-red-900",
    HIGH: "text-red-600",
    MEDIUM: "text-red-400",
    LOW: "text-yellow-400",
  };

  useEffect(() => {
    myStore.setAppliedFilters(
      getLocalConfig("OutstandingRecords_filter", location),
      false
    );
    myStore.id = recordId;
    myStore.fillData(false, (data) => {
      setIsLoading(false);
      setOutStandingRecords(data);
      setExternalOutstandingRecords(data);
    });
    fetchDynamicFilters("ManualMatchingFilters", (newFilters) => {
      setDynamicFilters(newFilters);
    });
  }, []);

  const pinningInitialState = ["pin"];
  const columns = [
    {
      accessorKey: "Mismatches",
      header: translate("outstanding-records.columns.Mismatches"),
      size: 220,
      sortable: true,
      canPin: false,
      canFilter: false,
      cell: ({ row }) => {
        return (
          <div className="flex flex-col gap-1">
            {row.original.Mismatches.map((mismatch, index) => (
              <span
                key={index}
                className={`rounded-full items-center justify-between gap-2 w-fit font-normal text-sm border border-r-10 border-transparent `}
              >
                <span className={`${textColors[mismatch.MismatchSeverityTag]}`}>
                  {mismatch.MismatchName}
                </span>
              </span>
            ))}
          </div>
        );
      },
    },
    {
      accessorKey: "MatchingSource",
      header: translate("outstanding-records.columns.MatchingSource"),
      size: 200,
      sortable: false,
      canPin: false,
      canFilter: false,
      cell: ({ row }) => {
        return (
          !!row.original.MatchingSource.MatchingSourceName &&
          row.original.MatchingSource.MatchingSourceName +
            ` (${translate("outstanding-records.columns.RunID")}: ${
              row.original.RunId
            })`
        );
      },
    },
    {
      accessorKey: "BusinessDay",
      header: translate("outstanding-records.columns.BusinessDay"),
      size: 150,
      sortable: true,
      canPin: false,
      canFilter: false,
      filterType: "date",
      cell: ({ row }) => {
        return (
          <span className="text-sm flex flex-col font-normal">
            <span>{row.original.BusinessDay}</span>
            <span>{row.original.BusinessDayInstance}</span>
          </span>
        );
      },
    },
    {
      accessorKey: "CreationDate",
      header: translate("outstanding-records.columns.CreationDate"),
      size: 150,
      sortable: true,
      canFilter: false,
      canPin: false,
      truncate: true,
      filterType: "date",
      splitCellValue: "T",
    },
    {
      accessorKey: "RecordMatchingKeys",
      header: translate("outstanding-records.columns.RecordMatchingKeys"),
      size: 150,
      sortable: true,
      canPin: false,
      cell: ({ row }) => {
        const values = row.original.RecordMatchingKeys;
        return (
          <div className="flex flex-col gap-1">
            {values.map((value, index) => {
              return <span key={index}>{value}</span>;
            })}
          </div>
        );
      },
    },
    {
      accessorKey: "SourceAmount",
      header: translate("outstanding-records.columns.SourceAmount"),
      size: 150,
      sortable: true,
      canPin: true,
      canFilter: false,
      filterType: "integer",
      operator: "EQ",
      processFilterValue: (value, row) => {
        return row.SourceAmount.toString();
      },
      cellActionCheck: (value) => value > 0,
      cell: ({ row }) => {
        return (
          row.original.SourceAmount && (
            <span className="flex flex-col items-center justify-center group font-normal">
              <span>{`${row.original.Currency} ${FormatWithCommas(
                row.original.SourceAmount
              )}`}</span>
              <span className="font-normal text-xs">{`${
                row.original.SourceRecords.length
              } ${
                row.original.SourceRecords.length === 1
                  ? translate("shared.record")
                  : translate("shared.records")
              }`}</span>
            </span>
          )
        );
      },
    },
    {
      accessorKey: "DestinationAmount",
      header: translate("outstanding-records.columns.DestinationAmount"),
      size: 200,
      sortable: true,
      canPin: true,
      canFilter: false,
      filterType: "integer",
      operator: "EQ",
      filterCheck: (value) => {
        return value !== null;
      },
      cellActionCheck: (value) => value > 0,
      cell: ({ row }) => {
        return (
          row.original.DestinationAmount && (
            <span className="flex flex-col items-center justify-center group font-normal">
              <span>{`${row.original.Currency} ${FormatWithCommas(
                row.original.DestinationAmount
              )}`}</span>
              <span className="font-normal text-xs">{`${
                row.original.DestinationRecords.length
              } ${
                row.original.DestinationRecords.length === 1
                  ? translate("shared.record")
                  : translate("shared.records")
              }`}</span>
            </span>
          )
        );
      },
    },
  ];

  const childrenColumns = [
    {
      accessorKey: "source",
      header: translate("outstanding-records.children-columns.source"),
      hideHeader: true,
      size: 20,
      cell: ({ row }) => {
        const colors = {
          Source: "text-green-500",
          Destination: "text-red-500",
        };
        return (
          <span
            className={clsx(
              `text-xs font-semibold flex gap-2 items-center`,
              colors[row.original.source] || "text-gray-400"
            )}
            title={row.original.source}
          >
            {row.original.source.split("")[0].toUpperCase()}
          </span>
        );
      },
    },
    {
      accessorKey: "ReconciliationStreamName",
      header: translate(
        "outstanding-records.children-columns.ReconciliationStreamName"
      ),
      size: 230,
      truncate: true,
    },
    {
      accessorKey: "MatchingSource",
      header: translate("outstanding-records.children-columns.DataSourceName"),
      size: 150,
      cell: ({ row }) => {
        return (
          !!row.original.MatchingSource.MatchingSourceName &&
          row.original.MatchingSource.MatchingSourceName +
            ` (${translate("outstanding-records.columns.RunID")}: ${
              row.original.RunId
            })`
        );
      },
    },
    {
      accessorKey: "AreaName",
      header: translate("outstanding-records.children-columns.AreaName"),
      size: 160,
    },
    {
      accessorKey: "OutletName",
      header: translate("outstanding-records.children-columns.OutletName"),
      size: 190,
    },
    {
      accessorKey: "TransactionTerminalCode",
      header: translate(
        "outstanding-records.children-columns.TransactionTerminalCode"
      ),
      size: 150,
    },
    {
      accessorKey: "RecordReference",
      header: translate("outstanding-records.children-columns.RecordReference"),
      size: 150,
    },
    {
      accessorKey: "RecordDate",
      header: translate("outstanding-records.children-columns.RecordDate"),
      size: 180,
      splitCellValue: "T",
    },
    {
      accessorKey: "RecordType",
      header: translate("outstanding-records.children-columns.RecordType"),
      size: 150,
    },
    {
      accessorKey: "Attributes",
      header: translate("outstanding-records.children-columns.Attributes"),
      size: 150,
      displayInPopup: true,
      popupCellDisplay: (value) => {
        return (
          <div className="relative text-primary-400 font-semibold">
            ({value.length})
          </div>
        );
      },
      popupContent: (row) => {
        const value = row.Attributes;
        return (
          <div className="relative ">
            <div className="flex flex-col min-w-[400px] transition-all bg-primary-dark text-[#777f9e] text-14 rounded-lg px-[2px] py-[8px] mb-3 opacity-100">
              {value &&
                value.map((attr, index) => (
                  <div
                    key={index}
                    className="flex flex-row justify-between gap-2 mb-[2px]"
                  >
                    <span className="py-[2px] whitespace-nowrap text-xs font-semibold">
                      {attr.Key}
                    </span>
                    <span className="relative py-[2px] flex gap-2 items-center px-[8px] whitespace-nowrap ltr:text-right rtl:text-left text-sm">
                      {attr.Value}
                      <div className=" absolute left-0 w-full justify-center flex opacity-0 hover:opacity-100 bg-gray-300 bg-opacity-50 rounded">
                        <CopyToCliboard size="5" value={attr.Value} />
                      </div>
                    </span>
                  </div>
                ))}
            </div>
          </div>
        );
      },
    },
    {
      accessorKey: "PaymentMethod",
      header: translate("outstanding-records.children-columns.PaymentMethod"),
      size: 180,
    },
    {
      accessorKey: "Amount",
      header: translate("outstanding-records.children-columns.Amount"),
      size: 130,
      cell: ({ row }) => {
        return (
          <span className="flex items-center justify-center group text-primary-400 font-semibold">
            {`${row.original.Currency} ${
              row.original.SourceAmount || row.original.DestinationAmount
            }`}
          </span>
        );
      },
    },
  ];

  const filters = [
    {
      mainFilters: [
        {
          key: "ReconciliationStreamName",
          displayName: translate("outstanding-records.filters.Reconciliations"),
          icon: () => (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"
              />
            </svg>
          ),
          components: [
            {
              name: translate("outstanding-records.filters.Reconciliations"),
              type: "title",
              key: "title",
            },
            {
              name: translate("outstanding-records.filters.RecordReference"),
              type: "searchInput",
              key: "RecordReference",
              filterType: "string",
              operator: "EQ",
            },
            {
              name: translate(
                "outstanding-records.filters.ReconciliationStreamName"
              ),
              type: "checkboxGroup",
              key: "ReconciliationStreamTag",
              values: getDynamicFilters("ReconciliationStreamTag"),
              filterType: "checkbox",
              operator: "EQ",
            },
          ],
        },
        {
          key: "AgingTierTag",
          displayName: translate("outstanding-records.filters.AgingTier"),
          icon: () => (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"
              />
            </svg>
          ),
          components: [
            {
              name: translate("outstanding-records.filters.AgingTier"),
              type: "title",
              key: "title",
            },
            {
              name: translate("outstanding-records.filters.AgingTier"),
              type: "checkboxGroup",
              key: "AgingTierTag",
              values: getDynamicFilters("AgingTierTag"),
              filterType: "checkbox",
              operator: "EQ",
            },
          ],
        },
        {
          key: "MismatchTag",
          displayName: translate("outstanding-records.filters.Mismatch"),
          icon: () => (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"
              />
            </svg>
          ),
          components: [
            {
              name: translate("outstanding-records.filters.Mismatch"),
              type: "title",
              key: "title",
            },
            {
              name: translate("outstanding-records.filters.Mismatch"),
              type: "checkboxGroup",
              key: "MismatchTag",
              values: getDynamicFilters("MismatchTag"),
              filterType: "checkbox",
              operator: "EQ",
            },
            {
              name: translate("outstanding-records.filters.MismatchSeverity"),
              type: "checkboxGroup",
              key: "MismatchSeverityTag",
              values: getDynamicFilters("MismatchSeverityTag"),
              filterType: "checkbox",
              operator: "EQ",
            },
          ],
        },
        {
          key: "Location",
          displayName: translate("outstanding-records.filters.Location"),
          icon: () => (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"
              />
            </svg>
          ),
          components: [
            {
              name: translate("outstanding-records.filters.Location"),
              type: "title",
              key: "title",
            },
            {
              name: translate("outstanding-records.filters.Area"),
              type: "checkboxGroup",
              key: "AreaId",
              values: getDynamicFilters("AreaId"),
              filterType: "checkbox",
              operator: "EQ",
            },
            {
              name: translate("outstanding-records.filters.Outlet"),
              type: "checkboxGroup",
              key: "OutletId",
              values: getDynamicFilters("OutletId"),
              filterType: "checkbox",
              operator: "EQ",
            },
            {
              name: translate("outstanding-records.filters.Terminal"),
              type: "checkboxGroup",
              key: "TransactionTerminalCode",
              values: getDynamicFilters("TransactionTerminalCode"),
              filterType: "checkbox",
              operator: "EQ",
            },
          ],
        },
        {
          key: "Amounts",
          displayName: translate("outstanding-records.filters.Amounts"),
          icon: () => (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"
              />
            </svg>
          ),
          components: [
            {
              name: translate("outstanding-records.filters.Amounts"),
              type: "title",
              key: "title",
            },
            {
              name: translate("outstanding-records.filters.SourceAmount"),
              type: "numberInput",
              key: "SourceAmount",
              filterType: "amount",
              inputType: "number",
            },
            {
              name: translate("outstanding-records.filters.DestinationAmount"),
              type: "numberInput",
              key: "DestinationAmount",
              filterType: "amount",
              inputType: "number",
            },
          ],
        },
        {
          key: "dates",
          displayName: translate("outstanding-records.filters.dates"),
          icon: () => (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
              />
            </svg>
          ),
          components: [
            {
              name: translate("outstanding-records.filters.PleaseSelectDate"),
              type: "title",
              key: "title",
            },
            {
              name: translate("outstanding-records.filters.CreationDate"),
              type: "dateInput",
              inputType: "date",
              key: "CreationDate",
              placeHolder: translate(
                "outstanding-records.filters.CreationDate"
              ),
              prefix: "",
              filterType: "date",
            },
            {
              name: translate(
                "outstanding-records.filters-display.BusinessDay"
              ),
              type: "dateInput",
              inputType: "date",
              key: "BusinessDay",
              placeHolder: translate(
                "outstanding-records.filters-display.BusinessDay"
              ),
              prefix: "",
              filterType: "date",
            },
          ],
        },
      ],
    },
  ];

  const selectedFiltersDisplay = {
    ReconciliationStreamTag: translate(
      "outstanding-records.filters-display.ReconciliationStreamTag"
    ),
    AgingTierTag: translate("outstanding-records.filters-display.AgingTierTag"),
    MismatchTag: translate("outstanding-records.filters-display.MismatchTag"),
    MismatchSeverityTag: translate(
      "outstanding-records.filters-display.MismatchSeverityTag"
    ),
    AreaId: translate("outstanding-records.filters-display.AreaId"),
    OutletId: translate("outstanding-records.filters-display.OutletId"),
    TransactionTerminalCode: translate(
      "outstanding-records.filters-display.TransactionTerminalCode"
    ),
    SourceAmount: translate("outstanding-records.filters-display.SourceAmount"),
    DestinationAmount: translate(
      "outstanding-records.filters-display.DestinationAmount"
    ),
    CreationDate: translate("outstanding-records.filters-display.CreationDate"),
    BusinessDay: translate("outstanding-records.filters-display.BusinessDay"),
    RecordReference: translate(
      "outstanding-records.filters-display.RecordReference"
    ),
  };

  const noData = () => {
    return (
      <div className="text-center w-full flex flex-col items-center gap-4">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21 12a2.25 2.25 0 0 0-2.25-2.25H15a3 3 0 1 1-6 0H5.25A2.25 2.25 0 0 0 3 12m18 0v6a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 18v-6m18 0V9M3 12V9m18 0a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 9m18 0V6a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 6v3"
          />
        </svg>
        <span>{translate("shared.no-data")}</span>
      </div>
    );
  };

  const renderChildTable = (children) => {
    return (
      <div className="relative  max-h-[230px] overflow-y-auto pl-10">
        <table className="bg-white text-center border-t-2 border-gray-200 relative min-w-max">
          <thead>
            <tr className="bg-white text-15 text-center border-b-2 sticky top-0 w-full shadow-[0px 0px 1px #e1e1e1]">
              <th width={50}></th>
              {childrenColumns.map((column, index) => {
                return (
                  <th
                    key={index}
                    style={{ width: `${parseInt(column.size)}px` }}
                    className="py-[10px] px-[10px] whitespace-nowrap text-xs border-r-0 border-gray-100 font-normal text-gray-400"
                  >
                    {!column.hideHeader && column.header}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="">
            {children.map((child, index) => {
              const checked =
                selectedOutstandingDestinationRows[child.PromotedRecordId];
              return (
                <tr
                  key={index}
                  className="text-secondary-dark hover:bg-bg-2 border-b border-line last:border-none"
                >
                  <td width={50}>
                    <input
                      type="checkbox"
                      className="size-4"
                      checked={checked}
                      onClick={() => {
                        setSelectedOutstandingDestinationRows((prev) => {
                          const newState = { ...prev };
                          if (newState.hasOwnProperty(child.PromotedRecordId)) {
                            if (checked) {
                              delete newState[child.PromotedRecordId];
                            }
                          } else {
                            newState[child.PromotedRecordId] = true;
                          }

                          return newState;
                        });
                      }}
                    />
                  </td>
                  {childrenColumns.map((column, index) => {
                    let cellValue = child[column.accessorKey];

                    return (
                      <td
                        key={index}
                        style={{ width: `${parseInt(column.size)}px` }}
                        className="py-[10px] px-[10px] border-r-0 border-gray-100"
                      >
                        {column.displayInPopup ? (
                          <div className="flex justify-center items-center">
                            {popupOpen === child.PromotedRecordId && (
                              <PopupComponent
                                open={popupOpen === child.PromotedRecordId}
                                rawValue={column.popupContent(child)}
                                setPopupOpen={setPopupOpen}
                                addBlur
                              />
                            )}

                            <span
                              className="cursor-pointer"
                              onClick={() => {
                                setPopupOpen(child.PromotedRecordId);
                              }}
                            >
                              {column.popupCellDisplay(cellValue)}
                            </span>
                          </div>
                        ) : (
                          (typeof cellValue === "string" || column.cell) &&
                          (column.cell
                            ? column.cell({ row: { original: child } })
                            : cellValue)
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };
  const renderTables = (oustandingRecords) => {
    const sourceData = [];
    const destinationData = [];
    let matchingRecords = [];
    let childrenRecords = [];
    oustandingRecords?.forEach((record) => {
      matchingRecords.push(record);
      if (record.SourceRecords.length > 0) {
        record.SourceRecords.forEach((sourceRecord, index) => {
          sourceData.push(sourceRecord);
          childrenRecords.push({
            parentRecordID: record.MatchingRecordId,
            ...sourceRecord,
            source: "Source",
          });
        });
      }
      if (record.DestinationRecords.length > 0) {
        record.DestinationRecords.forEach((destinationRecord, index) => {
          destinationData.push(destinationRecord);
          childrenRecords.push({
            parentRecordID: record.MatchingRecordId,
            ...destinationRecord,
            source: "Destination",
          });
        });
      }
    });

    return (
      <div className="p-5 w-full flex flex-col gap-5">
        <CustomTable
          language={i18n.language}
          externalData={matchingRecords}
          columnsWithoutPin={columns}
          loading={false}
          pinningInitialState={pinningInitialState}
          setParentSelectedValues={() => {}}
          prefix="AttemptMatching_OutstandingRecords"
          pinnedRowHeight={62}
          maxTableHeight={400}
          showFilter={false}
          minimalTable={true}
          hideActionColumn={true}
          noData={noData}
          allowRowPinning={false}
          pinCondition={(row) => {
            return true;
          }}
          expandedViewRow={(row) => {
            const children = childrenRecords.filter(
              (record) =>
                record.parentRecordID === row.original.MatchingRecordId
            );
            return <div className="">{renderChildTable(children)}</div>;
          }}
          selectableCondition={(row, isHeader) => {
            return false;
          }}
          setExternalSelectedState={(selectedRows) => {
            setSelectedOutstandingDestinationRows(selectedRows);
          }}
          autoFillData={false}
          rowCustomID={(row) => {
            return row.PromotedRecordId;
          }}
          customSubRows={(row) => {
            return row.DestinationRecords;
          }}
          initialTableVisibility={true}
        />
      </div>
    );
  };
  const filterDisplayValues = {};
  const multipleValueColumns = columns
    .filter((column) => column.isMultiple)
    .map((column) => column.accessorKey);

  return (
    <Observer>
      {() => {
        return isLoading || myStore.loading ? (
          <Loader dots withContainer />
        ) : (
          <div className="border-2 border-gray-200 rounded mr-5 text-black">
            <div className="p-5 pb-0 flex flex-col items-start gap-5 ml-12">
              <TitleComponent
                text={translate("outstanding-records.title")}
                size="md"
              />

              <FiltersWrapper
                filterDisplayValues={filterDisplayValues}
                filters={filters}
                selectedFiltersDisplay={selectedFiltersDisplay}
                store={myStore}
                prefix="OutstandingRecords"
                setParentSelectedValues={() => {}}
                multipleValueColumns={multipleValueColumns}
                setOutStandingRecords={setOutStandingRecords}
              />
            </div>
            <div className="pl-0">{renderTables(outstandingRecords?.data)}</div>
          </div>
        );
      }}
    </Observer>
  );
};
export default OutstandingRecords;
