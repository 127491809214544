import React, { useEffect, useMemo, useState } from "react";
import ChartComponent from "@components/ChartComponent";
import { createSwapy } from "swapy";
import ButtonComponent from "@components/ButtonComponent";
import clsx from "clsx";
import Store from "../ChartComponent/store";
import { ErrorBoundary } from "react-error-boundary";
import BoundaryFallback from "@components/ErrorBoundaryComponent";
const DashBoard = () => {
  const DEFAULT = {
    1: "a",
    2: "b",
    3: "c",
  };

  const [editMode, setEditMode] = useState(false);

  const editModeSlot = (identifier, name) => {
    return (
      <div
        className={`item transition-colors duration-300 text-primary-900 cursor-grab active:cursor-grabbing active:bg-primary-100 border p-4 border-primary-800 hover:border-primary-500 hover:text-primary-500 rounded select-none relative ${identifier}`}
        data-swapy-item={identifier}
      >
        <div className="w-full h-full flex items-center justify-center">
          {name}
        </div>
      </div>
    );
  };

  const FirstDashboard = ({ maxSeriesPerPage }) => {
    const firstDashboard = useMemo(
      () =>
        editMode ? (
          editModeSlot("a", "Business Day Closure Overview Chart")
        ) : (
          <ErrorBoundary FallbackComponent={BoundaryFallback}>
            <ChartComponent
              title="Business Day Closure Overview"
              ChartTag="BUSINESS_CLOSURE_OVERVIEW"
              ChartTypeTag="LINE"
              ScaleTag="BUSINESS_DAY_INSTANCES"
              maxSeriesPerPage={maxSeriesPerPage}
              uniqueID="business-day-closure-overview"
              myStore={new Store()}
              dynamicFilterTag="BusinessClosureOverviewFilters"
              filterMinHeight={300}
            />
          </ErrorBoundary>
        ),
      [editMode]
    );

    return firstDashboard;
  };

  const SecondDashboard = ({ maxSeriesPerPage }) => {
    const secondDashboard = useMemo(
      () =>
        editMode ? (
          editModeSlot("b", "Week Closure Overview Chart")
        ) : (
          <ErrorBoundary FallbackComponent={BoundaryFallback}>
            <ChartComponent
              title="Week Closure Overview"
              ChartTag="BUSINESS_CLOSURE_OVERVIEW"
              ChartTypeTag="LINE"
              ScaleTag="BUSINESS_DAY_INSTANCES"
              maxSeriesPerPage={maxSeriesPerPage}
              uniqueID="week-closure-overview"
              myStore={new Store()}
              dynamicFilterTag="BusinessClosureOverviewFilters"
              filterMinHeight={300}
            />
          </ErrorBoundary>
        ),
      [editMode]
    );

    return secondDashboard;
  };

  const ThirdDashboard = ({ maxSeriesPerPage }) => {
    const thirdDashboard = useMemo(
      () =>
        editMode ? (
          editModeSlot("c", "Month Closure Overview Chart")
        ) : (
          <ErrorBoundary FallbackComponent={BoundaryFallback}>
            <ChartComponent
              title="Month Closure Overview"
              ChartTag="BUSINESS_CLOSURE_OVERVIEW"
              ChartTypeTag="LINE"
              ScaleTag="BUSINESS_DAY_INSTANCES"
              maxSeriesPerPage={maxSeriesPerPage}
              uniqueID="month-closure-overview"
              myStore={new Store()}
              dynamicFilterTag="BusinessClosureOverviewFilters"
              filterMinHeight={300}
            />
          </ErrorBoundary>
        ),
      [editMode]
    );

    return thirdDashboard;
  };

  const getItemById = (itemId, maxSeriesPerPage) => {
    switch (itemId) {
      case "a":
        return <FirstDashboard key="a" maxSeriesPerPage={maxSeriesPerPage} />;
      case "b":
        return <SecondDashboard key="b" maxSeriesPerPage={maxSeriesPerPage} />;
      case "c":
        return <ThirdDashboard key="c" maxSeriesPerPage={maxSeriesPerPage} />;
      default:
        return <></>;
    }
  };

  const slotItems = localStorage.getItem("dashboard-order")
    ? JSON.parse(localStorage.getItem("dashboard-order"))
    : DEFAULT;

  useEffect(() => {
    if (editMode) {
      const container = document.querySelector(".dashboardSwap");
      const swapy = createSwapy(container, {
        swapMode: "hover",
      });
      swapy.onSwap(({ data }) => {
        localStorage.setItem("dashboard-order", JSON.stringify(data.object));
      });

      return () => {
        if (swapy) {
          swapy.destroy();
        }
      };
    }
  }, [editMode]);

  return (
    <>
      <div className="absolute w-full p-2 flex justify-end">
        <ButtonComponent
          className={clsx(
            "fixed top-3 text-sm min-w-max h-fit p-2 rounded-lg px-4 cursor-pointer bg-white",
            editMode ? "left-[calc(50%+5px)]" : "left-[calc(50%-30px)]"
          )}
          withoutBorder={true}
          id="refresh-selection"
          color="blue"
          onClick={() => {
            setEditMode(!editMode);
          }}
          ref={null}
          icon={null}
          text={editMode ? "Done" : "Customize Layout"}
        />
      </div>
      <div
        key={`dashboardContainer${editMode}`}
        className="dashboardSwap w-full p-2 flex flex-col gap-2"
      >
        <div
          className={clsx(
            `slot a min-h-[35vh]`,
            editMode && "flex items-center justify-center"
          )}
          data-swapy-slot="1"
        >
          {getItemById(slotItems["1"], 10)}
        </div>
        <div className="flex justify-between gap-2 min-h-[35vh]">
          <div className="flex w-1/2">
            <div
              className={clsx(
                `slot b min-h-[35vh] w-full`,
                editMode && "flex items-center justify-center"
              )}
              data-swapy-slot="2"
            >
              {getItemById(slotItems["2"], 5)}
            </div>
          </div>
          <div className="flex w-1/2">
            <div
              className={clsx(
                `slot c min-h-[35vh] w-full`,
                editMode && "flex items-center justify-center"
              )}
              data-swapy-slot="3"
            >
              {getItemById(slotItems["3"], 5)}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashBoard;
