import React, { useEffect, useRef } from "react";
import ApexTree from "apextree";
import { parameterize } from "@helpers/helpers";

const TreeComponent = ({ transformData }) => {
  const treeRef = useRef(null);

  useEffect(() => {
    const options = {
      contentKey: "data",
      width: "99%",
      height: 600,
      nodeWidth: 200,
      nodeHeight: 100,
      fontColor: "#fff",
      borderColor: "#333",
      borderColorHover: "#03256c",
      edgeColor: "#BCBCBC",
      edgeColorHover: "#03256c",
      enableTooltip: false,
      tooltipId: "apextree-tooltip-container",
      tooltipTemplate: (content) => {
        return `<div style='display: flex; justify-content: center; align-items: center; text-align: center; height: 100%; padding: ${
          content?.tooltip ? "2px 7px" : ""
        };'>${content?.tooltip || ""}</div>`;
      },
      tooltipMaxWidth: "max-content",
      tooltipBorderColor: "#BCBCBC",
      tooltipBGColor: "#ffffff",
      fontSize: "14px",
      fontFamily: undefined,
      fontWeight: 400,
      fontSize: "#000000",
      childrenSpacing: 50,
      siblingSpacing: 20,
      direction: "top",
      enableExpandCollapse: true,
      nodeTemplate: (content) => {
        return `<div id="${parameterize(content?.name)}" style='font-size: ${
          content?.fontSize || "15px"
        };display: flex;flex-direction: column;gap: 10px;justify-content: center;align-items: center;height: 100%; padding: 5px 10px;'>
        <div style="font-weight: bold; font-family: Arial; max-height:80px; overflow-y: auto; text-align:center;">${
          content?.name
        }</div>
       </div>`;
      },
      canvasStyle: "border: 1px solid rgb(229, 231, 235);",
      enableToolbar: true,
    };
    document.getElementById("svg-tree").innerHTML = "";
    const tree = new ApexTree(treeRef.current, options);

    tree.render(transformData);
  }, []);

  return (
    <div
      id="svg-tree"
      ref={treeRef}
      style={{ width: "100%", height: "600px" }}
    />
  );
};

export default TreeComponent;
