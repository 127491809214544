import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import TooltipComponent from "@components/TooltipComponent";
import InfoModalStore from "@stores/InfoModalStore";
import ReportsModalStore from "@stores/ReportsModalStore";
import DownloadModalStore from "@stores/DownloadModalStore";

const SideBarItem = ({
  index,
  Menu,
  activeIndex,
  setActiveIndex,
  open,
  setOpenSubMenus,
  openSubMenus,
  setOpen,
  darkMode
}) => {
  const { t: translate } = useTranslation();
  const handleSubMenuToggle = (index) => {
    setOpenSubMenus((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };
  const navigate = useNavigate();
  const isActive = index === activeIndex;
  return (
    <li
      key={index}
      className={clsx(
        `flex flex-col cursor-pointer  text-sm has-tooltip group`,
        open ? "gap-y-2" : "gap-y-0",
        darkMode ? "text-white" : "text-primary-800"
        // Menu.gap ? "mt-9" : "mt-2"
      )}
      onClick={(e) => {
        // setActiveIndex(index)
        e.stopPropagation();
        setOpenSubMenus({});
        setOpen(false);
        setActiveIndex(index);
        InfoModalStore.setShowing(false);
        DownloadModalStore.close();
        !Menu.isReports && ReportsModalStore.close();
      }}
      title={translate(`menu.${Menu.title}`)} // Add title attribute here
    >
      {Menu.gap && (
        <div
          className={clsx(
            "h-[1px] bg-primary-700 bg-opacity-20",
            open ? "mt-1 mb-1" : "mt-1 mb-1"
          )}
        ></div>
      )}
      <div
        className={clsx(
          `flex items-center gap-x-4 font-normal text-[14px] hover:text-[15px] hover:font-bold opacity-80 hover:opacity-100 hover:bg-skyzz-200  rounded-md p-2 ltr:pr-0 rtl:pl-0 justify-between grayscale hover:grayscale-0`,
          isActive && "bg-skyzz-100 grayscale-0 !font-bold !opacity-100"
        )}
        title={translate(`menu.${Menu.title}`)}
        onClick={() => {
          setActiveIndex(index);
          Menu.subItems && handleSubMenuToggle(index);
          !Menu.isReports
            ? navigate(Menu.path)
            : ReportsModalStore.showing ? ReportsModalStore.close() : ReportsModalStore.open({
                type: "Acquisition_Instances_Info",
                title: translate("reports.titles.ReportTitle"),
                data: [],
                show: true,
              });
        }}
        data-tooltip-id={`tooltip-menu-${Menu.title}`}
      >
        {!open && (
          <TooltipComponent
            anchorElement={`tooltip-menu-${Menu.title}`}
            toolTipMessage={translate(`menu.${Menu.title}`)}
            position="top"
            className="max-w-96"
          />
        )}

        <div
          className={clsx(
            `flex gap-x-4`,
            !open ? "flex-col items-start" : "items-center"
          )}
        >
          <div
            className={clsx(
              "scale-90 group-hover:scale-100",
              isActive && "!scale-100"
            )}
          >
            {Menu.svgIcon}
          </div>
          {!Menu.svgIcon && (
            <img src={require(`@assets/${Menu.icon}.png`)} alt={Menu.title} />
          )}
          {(open || Menu.tinyTitle) && (
            <span
              className={clsx(
                `origin-left duration-200`,
                !open && "text-xs min-w-20"
              )}
            >
              {open ? translate(`menu.${Menu.title}`) : Menu.tinyTitle}
            </span>
          )}
        </div>
        {Menu.subItems && open && (
          <svg
            className={clsx(
              `w-4 h-4 transition-transform duration-300`,
              openSubMenus[index] ? "rotate-90" : "rotate-0"
            )}
            fill="none"
            stroke="white"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 5l7 7-7 7"
            />
          </svg>
        )}
      </div>
      {Menu.subItems && (
        <ul
          className={clsx(
            `pl-8 overflow-hidden transition-all duration-200 ease-in-out`,
            openSubMenus[index] ? "max-h-dvh" : "max-h-0"
          )}
        >
          {Menu.subItems.map((subItem, subIndex) => (
            <li
              key={`sidebarItem_${subIndex}`}
              className={clsx(
                `flex items-center relative hover:bg-sky-400 gap-y-2 mb-5 gap-x-4 mt-2 rounded-md p-2`,
                "subIndex_" + subIndex === activeIndex && "bg-sky-400"
              )}
              onClick={(e) => {
                
                e.stopPropagation();
                setOpenSubMenus({});
                setOpen(false);
                setActiveIndex(`subIndex_${subIndex}`);
                InfoModalStore.setShowing(false);
                ReportsModalStore.close();
                DownloadModalStore.close();
 
              }}
            >
              {subItem.svgIcon}
              {subItem.icon && !subItem.svgIcon ? (
                <img
                  src={require(`@assets/${subItem.icon}.png`)}
                  alt={subItem.title}
                />
              ) : null}
              <span>{subItem.title}</span>
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};

export default SideBarItem;
