import React, {useState} from 'react';
import TenantDetails from './TenantDetails';

function NewMatching() {
  const [key, setKey] = useState(0);

  const resetComponent = () => {
    setKey(prevKey => prevKey + 1);
  };

  return (
    <div className='w-full flex flex-col items-center'>
      <TenantDetails key={key} resetComponent={resetComponent} />
    </div>
  );
}

export default NewMatching