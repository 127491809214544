import { observable, action, makeObservable } from "mobx";

class ReportsModalStore {
  showing = false;
  title = "";
  type = "";
  data = null;
  afterClose = null;
  reports = [];

  constructor() {
    makeObservable(this, {
      showing: observable,
      title: observable,
      type: observable,
      data: observable,
      afterClose: observable,
      open: observable,
      close: action,
      reports: observable,
      setReports: action,
      setLocalStorageReports: action,
    });
    // const savedReports = localStorage.getItem("reports");
    // if (savedReports) {
    //   this.setReports(JSON.parse(savedReports));
    // }
  }

  setReports(reports) {
    this.reports = reports;
  }
  setLocalStorageReports(reports) {
    this.setReports(reports);
    localStorage.setItem("reports", JSON.stringify(reports));
  }

  setShowing(showing) {
    this.showing = showing;
  }

  open(modalObject, afterClose = null) {
    if (!this.showing) {
      if (modalObject.show) {
        this.setShowing(modalObject.show);
        this.title = modalObject.title;
        this.type = modalObject.type;
        this.data = modalObject.data;
        this.afterClose = afterClose;
      }
    }
  }

  close() {
    this.setShowing(false);
    this.title = "";
    this.type = "";
    this.data = null;
    this.reports = null;
    this.afterClose && this.afterClose();
  }
}

const store = new ReportsModalStore();
export default store;

