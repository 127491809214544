import React, { forwardRef } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

const ButtonComponent = forwardRef((props, ref) => {
  const {
    className,
    id,
    onClick,
    icon,
    text,
    isactive,
    type,
    color,
    withoutBorder = false,
    align = "center",
    minWidth = "min-w-40",
  } = props;
  const commonStyles = `
  inline-flex group transition-colors ease-in-out duration-[800ms] items-center gap-2 font-semibold w-fit rounded px-[12px] py-[7px] text-md font-semibold transition-all duration-200 disabled:bg-gray-300 disabled:text-gray-500 disabled:opacity-75 disabled:border-gray-300 disabled:cursor-not-allowed 
    ${
      withoutBorder ? "border-0" : "border-[1px] border-solid shadow-sm"
    } justify-${align} border-opacity-80 ${minWidth && minWidth}
  `;
  const colors = {
    blue: "text-primary-500  border-primary-500 hover:border-primary-500 hover:bg-primary-500 hover:text-white",
    "blue-active": "border-primary-500 bg-primary-500 text-white",
    red: "text-red-500 border-red-500 hover:border-red-500 hover:bg-red-500 hover:text-white",
    "red-active": "border-red-500 bg-red-500 text-white",
    gray: "text-gray-500 border-gray-500 hover:border-gray-500 hover:bg-gray-500 hover:text-white",
    "gray-active": "border-red-500 bg-red-500 text-white",
    green:
      "text-green-500 border-green-500 hover:border-green-500 hover:bg-green-500 hover:text-white",
    "green-active": "border-green-500 bg-green-500 text-white",
    indigo:
      "text-primary-500 border-primary-500 hover:border-primary-500 hover:bg-primary-500 hover:text-white",
    "primary-active": "border-primary-500 bg-primary-500 text-white",
  };
  const types = {
    cancel:
      "text-gray-500 border-gray-500 hover:border-red-500 hover:bg-red-500 hover:text-white",
    reset:
      "border-0 text-primary-500 transition-all duration-200 hover:border-primary-500 hover:bg-primary-500 hover:text-white",
    apply:
      " bg-transparent text-md text-primary-500 border-2 border-primary-500 transition-all duration-200 hover:border-green-500 hover:bg-green-500 hover:text-white",
    subButton:
      "w-full text-start hover:bg-gray-100 font-normal cursor-pointer p-2 px-4",
  };
  const activeSelector = `${color}-active`;
  return (
    <button
      {...props}
      ref={ref}
      type="button"
      className={clsx(
        commonStyles,
        colors[color] || "",
        isactive && colors[activeSelector] && colors[activeSelector],
        types[type] && types[type],
        className
      )}
      id={id}
      aria-haspopup="true"
      aria-expanded="true"
      onClick={onClick}
    >
      {icon}
      {text}
    </button>
  );
});

ButtonComponent.displayName = "ButtonComponent";

ButtonComponent.propTypes = {
  withoutBorder: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.oneOf(["blue", "red", "gray", "green"]),
};

ButtonComponent.defaultProps = {
  withoutBorder: false,
  className: null,
};

export default ButtonComponent;
