import React from "react";

const BoundaryFallback = ({ error, resetErrorBoundary }) => {
  return (
    <div role="alert">
      <p className="text-primary-900">Something went wrong:</p>
      <span className="text-red-600">{error.message}</span>
    </div>
  );
};

export default BoundaryFallback;
